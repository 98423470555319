import { useState } from 'react';
import s from '../calculator-container.module.scss';
const styles = s;
const formatCurrency = (amount: number) => {
  return `$${amount.toLocaleString()}`;
};
const preferredReturn = (principal: number, annualRate: number, days: number) => {
  const dailyRate = annualRate / 365;
  return principal * dailyRate * days;
};

const preferredReturnWithMinDays = (
  principal: number,
  annualRate: number,
  actualDays: number,
  minDays: number
) => {
  const days = Math.max(actualDays, minDays);
  return preferredReturn(principal, annualRate, days);
};

const profitShare = (totalProfit: number, profitPercentage: number, days: number) => {
  const investorProfit = totalProfit * (profitPercentage / 100);
  const annualizedReturn = (investorProfit / totalProfit) * (365 / days);
  return { investorProfit, annualizedReturn };
};

const combinedReturn = (
  principal: number,
  annualRate: number,
  totalProfit: number,
  profitPercentage: number,
  actualDays: number,
  minDays: number | null
) => {
  const prefReturn = minDays
    ? preferredReturnWithMinDays(principal, annualRate, actualDays, minDays)
    : preferredReturn(principal, annualRate, actualDays);

  const remainingProfit = totalProfit - prefReturn;
  const { investorProfit, annualizedReturn } = profitShare(
    remainingProfit,
    profitPercentage,
    actualDays
  );

  const totalReturn = prefReturn + investorProfit;
  return { totalReturn, annualizedReturn };
};

function CustomCalcs({
  calculatorName,
  formValues,
  setFormValues,
  handleStringInputChange,
  solveFor
}: any) {
  const [option, setOption] = useState('');
  const [fields, setFields] = useState({});
  const [result, setResult] = useState(null);
  const {
    pa: principal,
    annualRate,
    days,
    minDays,
    profit: totalProfit,
    profit_percentage: profitPercentage
  } = formValues;
  let calculationResult: any = null;

  const calculateResult = () => {
    switch (solveFor) {
      case 'preferredReturn':
        const prefReturn = preferredReturn(
          parseFloat(principal),
          parseFloat(annualRate),
          parseInt(days)
        );
        calculationResult = `For an investment of ${formatCurrency(
          parseFloat(principal)
        )} with an annual rate of ${annualRate}%, over ${days} days, the total preferred return is ${formatCurrency(
          prefReturn
        )}.`;
        break;
      case 'preferredReturnWithMinDays':
        const prefReturnMinDays = preferredReturnWithMinDays(
          parseFloat(principal),
          parseFloat(annualRate),
          parseInt(days),
          parseInt(minDays)
        );
        calculationResult = `With a minimum investment duration of ${minDays} days, the preferred return for ${days} days is ${formatCurrency(
          prefReturnMinDays
        )} on a principal of ${formatCurrency(parseFloat(principal))}.`;
        break;
      case 'percentageOfProfit':
        const { investorProfit, annualizedReturn } = profitShare(
          parseFloat(totalProfit),
          parseFloat(profitPercentage),
          parseInt(days)
        );
        calculationResult = `You will receive ${formatCurrency(
          investorProfit
        )} as your share of the profit from a total profit of ${formatCurrency(
          parseFloat(totalProfit)
        )}, which is an annualized return of ${(annualizedReturn * 100).toFixed(2)}%.`;
        break;
      case 'combination':
        const { totalReturn, annualizedReturn: comboAnnualReturn } = combinedReturn(
          parseFloat(principal) || 0,
          parseFloat(annualRate) || 0,
          parseFloat(totalProfit) || 0,
          parseFloat(profitPercentage) || 0,
          parseInt(days) || 0,
          minDays ? parseInt(minDays) || 0 : null
        );
        calculationResult = `Total Return: ${totalReturn.toFixed(
          2
        )}, Annualized Return: ${comboAnnualReturn.toFixed(2)}%`;
        calculationResult = `With a principal of ${formatCurrency(
          parseFloat(principal)
        )}, an annual rate of ${annualRate}%, and a profit share of ${profitPercentage}%, your total return is ${formatCurrency(
          totalReturn
        )}. This results in an annualized return of ${(comboAnnualReturn * 100).toFixed(2)}%.`;
        break;
      default:
        calculationResult = 'Select a valid option.';
    }

    setResult(calculationResult);
  };

  return (
    <div>
      {calculatorName === 'CALCULATE_DAYS_BETWEEN' && (
        <>
          <div className={styles.form_item}>
            <p>Enter dates</p>
            <div className={styles.input_container}>
              <input
                type='date'
                onChange={e => handleStringInputChange('from_date', e.target.value)}
                value={formValues['from_date'] ? formValues['from_date'] : ''}
              />
              <span style={{ margin: '0px 9px' }}>To</span>
              <input
                type='date'
                onChange={e => handleStringInputChange('to_date', e.target.value)}
                value={formValues['from_date'] ? formValues['to_date'] : ''}
              />
            </div>
          </div>

          <div className={styles.form_item} style={{ marginTop: '15px' }}>
            <div className={styles.input_container}>
              <input
                type='checkbox'
                onChange={e => handleStringInputChange('first_day', e.target.checked)}
                checked={formValues['first_day'] ? formValues['first_day'] : ''}
                style={{ width: '14px', height: '14px' }}
              />
              <span style={{ margin: '0px 9px' }}>Count first day</span>
              <input
                type='checkbox'
                onChange={e => handleStringInputChange('last_day', e.target.checked)}
                value={formValues['last_day'] ? formValues['last_day'] : ''}
                style={{ width: '14px', height: '14px' }}
              />
              <span style={{ margin: '0px 9px' }}>Count last day</span>
            </div>
          </div>
        </>
      )}
      {calculatorName === 'RETURN_ON_INVESTMENT' && (
        <>
          {solveFor === 'preferredReturn' && (
            <>
              <div className={styles.form_item}>
                <p>Principal Amount</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('pa', e.target.value)}
                    value={formValues['pa'] ? formValues['pa'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Annual Rate (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('annualRate', e.target.value)}
                    value={formValues['annualRate'] ? formValues['annualRate'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Number of Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('days', e.target.value)}
                    value={formValues['days'] ? formValues['days'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>
            </>
          )}

          {solveFor === 'preferredReturnWithMinDays' && (
            <>
              <div className={styles.form_item}>
                <p>Principal Amount</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('pa', e.target.value)}
                    value={formValues['pa'] ? formValues['pa'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Annual Rate (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('annualRate', e.target.value)}
                    value={formValues['annualRate'] ? formValues['annualRate'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Number of Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('days', e.target.value)}
                    value={formValues['days'] ? formValues['days'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Minimum Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('minDays', e.target.value)}
                    value={formValues['minDays'] ? formValues['minDays'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>
            </>
          )}

          {solveFor === 'percentageOfProfit' && (
            <>
              <div className={styles.form_item}>
                <p>Principal Amount</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('pa', e.target.value)}
                    value={formValues['pa'] ? formValues['pa'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Annual Rate (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('annualRate', e.target.value)}
                    value={formValues['annualRate'] ? formValues['annualRate'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Number of Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('days', e.target.value)}
                    value={formValues['days'] ? formValues['days'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Total Profit</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('profit', e.target.value)}
                    value={formValues['profit'] ? formValues['profit'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Profit Percentage (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('profit_percentage', e.target.value)}
                    value={formValues['profit_percentage'] ? formValues['profit_percentage'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
            </>
          )}

          {solveFor === 'combination' && (
            <>
              <div className={styles.form_item}>
                <p>Principal Amount</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('pa', e.target.value)}
                    value={formValues['pa'] ? formValues['pa'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Annual Rate (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('annualRate', e.target.value)}
                    value={formValues['annualRate'] ? formValues['annualRate'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Number of Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('days', e.target.value)}
                    value={formValues['days'] ? formValues['days'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>

              <div className={styles.form_item}>
                <p>Minimum Days</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('minDays', e.target.value)}
                    value={formValues['minDays'] ? formValues['minDays'] : ''}
                  />
                  <div className={styles.input_after}>days</div>
                </div>
              </div>

              <div className={styles.form_item}>
                <p>Total Profit</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('profit', e.target.value)}
                    value={formValues['profit'] ? formValues['profit'] : ''}
                  />
                  <div className={styles.input_after}>$</div>
                </div>
              </div>
              <div className={styles.form_item}>
                <p>Profit Percentage (%)</p>
                <div className={styles.input_container}>
                  <input
                    type='number'
                    onChange={e => handleStringInputChange('profit_percentage', e.target.value)}
                    value={formValues['profit_percentage'] ? formValues['profit_percentage'] : ''}
                  />
                  <div className={styles.input_after}>%</div>
                </div>
              </div>
            </>
          )}
          <button onClick={calculateResult} className={styles.button_wrapper}>
            Calculate
          </button>
          {result && (
            <div className={styles.info} style={{ marginTop: '15px' }}>
              <div>Result: </div>
              <p>{result}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CustomCalcs;
