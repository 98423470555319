import React, { useEffect, useState } from 'react';
import s from '../calculator-container.module.scss';
import { cx } from '../../../utils/class-name-service';
interface FormValues {
  [key: string]: string;
}
const styles = s;

function TableRow({
  handleInputChange,
  handleStringInputChange,
  formValues,
  label,
  name,
  col2,
  col3,
  col2Label,
  col3Label,
  labelArray,
  index
}: any) {
  return (
    <div className={styles.tr}>
      {Boolean(labelArray && labelArray.length > 0) ? (
        <div className={styles.th}>
          <div>
            {labelArray.map((el: any) => {
              return (
                <>
                  {el.type === 'text' && <span>{el.value}</span>}
                  {el.type === 'input' && (
                    <input
                      style={{
                        width: '100px',
                        marginLeft: '5px',
                        borderBottom: '1px solid #5d5d5d'
                      }}
                      type='text'
                      onChange={e => handleStringInputChange(el.name, e.target.value)}
                      value={formValues[el.name]}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.th} style={{ fontWeight: index == 0 ? '600' : 400 }}>
          {label}
        </div>
      )}
      {/* <div className={styles.th}>{label}</div> */}
      {col2 && (
        <div className={styles.th}>
          <div className={styles.input_container}>
            {col2Label ? (
              <span>{col2Label}</span>
            ) : (
              <input
                style={{ width: '100%' }}
                type='text'
                onChange={e => handleStringInputChange(name, e.target.value)}
                value={formValues[name]}
              />
            )}
          </div>
        </div>
      )}
      {col3 && (
        <div className={styles.th}>
          {col3Label ? (
            <span>{col2Label}</span>
          ) : (
            <input
              style={{ width: '100%' }}
              type='text'
              onChange={e => handleStringInputChange(`${name}-col3`, e.target.value)}
              value={formValues[`${name}-col3`]}
            />
          )}
        </div>
      )}
    </div>
  );
}
function FormItem({ formArray, handleInputChange, handleStringInputChange, formValues }: any) {
  return (
    <div className={s.form}>
      {formArray.map((element: any) => {
        return (
          <>
            {element.type !== 'big_string' ? (
              <div className={s.form_item}>
                <p>{element.label}</p>
                {element.type === 'number' && (
                  <div className={s.input_container}>
                    <input
                      type='text'
                      onChange={e => handleInputChange(element.name, e.target.value, element.unit)}
                      value={formValues[element.name]}
                    />
                    {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                  </div>
                )}
                {element.type === 'string' && (
                  <div className={s.input_container}>
                    <input
                      type='text'
                      onChange={e => handleStringInputChange(element.name, e.target.value)}
                      value={formValues[element.name]}
                    />
                    {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                  </div>
                )}
                {element.type === 'string_long' && (
                  <div className={s.input_container} style={{ width: '80%' }}>
                    <input
                      type='text'
                      onChange={e => handleStringInputChange(element.name, e.target.value)}
                      value={formValues[element.name]}
                      style={{ width: '100%' }}
                    />
                    {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                  </div>
                )}
                {element.type === 'text_area' && (
                  <div className={s.input_container} style={{ width: '80%', height: '100px' }}>
                    <textarea
                      onChange={e => handleStringInputChange(element.name, e.target.value)}
                      value={formValues[element.name]}
                      style={{ width: '100%', height: '100%' }}
                    />
                    {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                  </div>
                )}
                {element.type === 'select' && (
                  <div className={s.input_container}>
                    <select
                      onChange={e => handleInputChange(element.name, e.target.value, element.unit)}
                      value={formValues[element.name]}
                    >
                      {element.option.map((el: any) => (
                        <option value={el.id}>{el.value}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={s.form_item}
                style={{
                  alignItems: 'flex-start'
                }}
              >
                <p>{element.label}</p>

                <div className={s.input_container_big} style={{ width: '80%' }}>
                  <textarea
                    onChange={e => handleStringInputChange(element.name, e.target.value)}
                    value={formValues[element.name]}
                    style={{ width: '100%', height: '100px' }}
                  />
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}
function SectionTitle({ char, title }: any) {
  return (
    <div className={s.section_title}>
      <div className={s.section_title_char}>{char}</div>
      <div className={s.section_title_text}>{title}</div>
    </div>
  );
}

function HUD({ data, formValues, setFormValues }: any) {
  const [checkedItems, setCheckedItems] = useState<any>({});
  // const [formValues, setFormValues] = useState<FormValues>({});
  const [page, setPage] = useState(1);
 
  console.log(data, 187);
  const calculateSum = (start: number, end: number) => {
    let sum = 0;
    for (let i = start; i <= end; i++) {
      const value = formValues[i.toString()];
      if (value) {
        sum += parseInt(value.replace(/,/g, ''), 10);
      }
    }
    return sum.toLocaleString();
  };
  const handleCheckboxChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  const handleInputChange = (label: any, value: any) => {
    const inputValue = value.replace(/[^0-9]/g, '');
    const formatted = Number(inputValue).toLocaleString();

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
  };
  const handleStringInputChange = (label: any, value: any) => {
    const inputValue = value;
    const formatted = inputValue;

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
  };
  const b_checkboxes = [
    { name: 'FHA', label: 'FHA' },
    { name: 'RHS', label: 'RHS' },
    { name: 'ConvUnins', label: 'Conv. Unins.' },
    { name: 'VA', label: 'VA' },
    { name: 'ConIns', label: 'Con. Ins.' }
  ];
  const b_form = [
    {
      label: 'File Number',
      type: 'number',
      unit: '',
      name: 'b_file'
    },
    {
      label: 'Loan Number',
      type: 'number',
      unit: '',
      name: 'b_loan'
    },
    {
      label: 'Mortgage Insurance Case Number',
      type: 'number',
      unit: '',
      name: 'b_morgage'
    }
  ];

  const d_form = [
    {
      label: 'Name',
      type: 'string_long',
      unit: '',
      name: 'd_name'
    },
    {
      label: 'Address',
      type: 'big_string',
      unit: '',
      name: 'd_address'
    }
  ];
  const e_form = [
    {
      label: 'Name',
      type: 'string_long',
      unit: '',
      name: 'e_name'
    },
    {
      label: 'Address',
      type: 'big_string',
      unit: '',
      name: 'e_address'
    }
  ];
  const f_form = [
    {
      label: 'Name',
      type: 'string_long',
      unit: '',
      name: 'f_name'
    },
    {
      label: 'Address',
      type: 'big_string',
      unit: '',
      name: 'f_address'
    }
  ];
  const g_form = [
    {
      label: 'Enter Location',
      type: 'big_string',
      unit: '',
      name: 'g_name'
    }
  ];
  const h_form = [
    {
      label: 'Name',
      type: 'string_long',
      unit: '',
      name: 'h_name'
    },
    {
      label: 'Address',
      type: 'big_string',
      unit: '',
      name: 'h_address'
    },
    {
      label: 'Palce of settlement',
      type: 'big_string',
      unit: '',
      name: 'h_place'
    }
  ];
  const i_form = [
    {
      label: 'Settlement Date',
      type: 'string',
      unit: '',
      name: 'i_date'
    }
  ];

  return (
    <>
      {page === 1 && (
        <div>
          <SectionTitle
            char='A'
            title='"Settlement Statement" US Department of Housing and Urban Development OMB Number 2502-0265'
          />
          <div className={s.divider}></div>

          <SectionTitle char='B' title='Type of Loan' />
          <div className={s.check_group}>
            {b_checkboxes.map((item: any) => (
              <label key={item.name} className={s.check_label}>
                <input
                  type='checkbox'
                  name={item.name}
                  checked={checkedItems[item.name] || false}
                  onChange={handleCheckboxChange}
                  className={s.check_input}
                />
                {item.label}
              </label>
            ))}
          </div>

          <FormItem
            formArray={b_form}
            handleInputChange={handleInputChange}
            handleStringInputChange={handleStringInputChange}
            formValues={formValues}
          />

          <div style={{ margin: '24px 0px' }}>
            <SectionTitle
              char='C'
              title='NOTE: This form is furnished to give you a statement of actual settlement costs. Amounts paid to and by the settlement agent are shown. Items marked "(p.o.c.)" were paid outside the closing; they are shown here for informational purposes and are not included in the totals.'
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='D' title='Name and Address of Borrower' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={d_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='E' title='Name and Address of Seller' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={e_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='F' title='Name and Address of Lender' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={f_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='G' title='Property Location' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={g_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='H' title='Settlement Agent: Name and Address' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={h_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div style={{ margin: '24px 0px' }}>
            <SectionTitle char='I' title='Type of Loan' />
          </div>
          <div className={s.form}>
            <FormItem
              formArray={i_form}
              handleInputChange={handleInputChange}
              handleStringInputChange={handleStringInputChange}
              formValues={formValues}
            />
          </div>
          <div className={s.divider}></div>

          <div className={s.pagination}>
            <div>Page 1 of 2</div>
            <button onClick={() => setPage(2)}>Go to page 2</button>
          </div>
        </div>
      )}

      {page === 2 && (
        <div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div>
              <SectionTitle char='J' title='Summary of Borrower’s transaction' />
              <div className={cx(styles.table, styles.table_60_40)}>
                {[
                  { label: '100. Gross Amount due from Borrower', name: '100', col2: false },
                  { label: '101. Contact sales price', name: '101', col2: true },
                  { label: '102. Personal property', name: '102', col2: true },
                  {
                    label: '103. Settlement charges to borrower (Line 1400)',
                    name: '103',
                    col2: true
                  },
                  { label: '104.', name: '104', col2: true },
                  { label: '105.', name: '105', col2: true },
                  { label: '106. City/town taxes', name: '106', col2: true },
                  { label: '107. County taxes', name: '107', col2: true },
                  { label: '108. Assessments', name: '108', col2: true },
                  { label: '109.', name: '109', col2: true },
                  { label: '110.', name: '110', col2: true },
                  { label: '111.', name: '111', col2: true },
                  { label: '112.', name: '112', col2: true },
                  { label: '120. Gross Amount Due From Borrower', name: '120', col2: true }
                ].map((row, i) => (
                  <TableRow
                    handleInputChange={handleInputChange}
                    handleStringInputChange={handleStringInputChange}
                    formValues={{ ...formValues, '120': calculateSum(101, 112) }}
                    label={row.label}
                    name={row.name}
                    col2={row.col2}
                    index={i}
                  />
                ))}
              </div>

              <div style={{ marginTop: '24px' }}></div>

              <div className={cx(styles.table, styles.table_60_40)}>
                {[
                  {
                    label: '200. AMOUNTS PAID BY OR IN BEHALF OF BORROWER',
                    name: '200',
                    col2: false
                  },
                  { label: '201. Deposit of earnest money', name: '201', col2: true },
                  { label: '202. Principal amount of new loan(s)', name: '202', col2: true },
                  { label: '203. Existing loan(s) taken subject to', name: '203', col2: true },
                  { label: '204.', name: '204', col2: true },
                  { label: '205.', name: '205', col2: true },
                  { label: '206.', name: '206', col2: true },
                  { label: '207.', name: '207', col2: true },
                  { label: '208.', name: '208', col2: true },
                  { label: '209.', name: '209', col2: true },
                  {
                    label: 'Adjustments for items unpaid by Seller',
                    name: '200_adjustment',
                    col2: true
                  },
                  { label: '210. City/town taxes', name: '210', col2: true },
                  { label: '211. County taxes', name: '211', col2: true },
                  { label: '212. Assessments', name: '212', col2: true },
                  { label: '213. Assessments', name: '213', col2: true },
                  { label: '214. Assessments', name: '214', col2: true },
                  { label: '215. Assessments', name: '215', col2: true },
                  { label: '216. Assessments', name: '216', col2: true },
                  { label: '217. Assessments', name: '217', col2: true },
                  { label: '218. Assessments', name: '218', col2: true },
                  { label: '219. Assessments', name: '219', col2: true },
                  { label: '220. Total Paid by/for Brrower', name: '220', col2: true }
                ].map(row => (
                  <TableRow
                    handleInputChange={handleInputChange}
                    handleStringInputChange={handleStringInputChange}
                    formValues={{ ...formValues, '220': calculateSum(201, 219) }}
                    label={row.label}
                    name={row.name}
                    col2={row.col2}
                  />
                ))}
                <div style={{ margin: '24px 0px 24px 0px' }}>
                  {[
                    { label: '300. CASH AT SETTLEMENT FROM/TO BORROWER', name: '300', col2: false },
                    {
                      label: '301. Gross amount due from Borrower (line 120)',
                      name: '301',
                      col2: true
                    },
                    {
                      label: '302. Less amounts paid by/for Borrower (line 220)',
                      name: '302',
                      col2: true
                    },
                    { label: '303. Cash', name: '303', col2: true }
                  ].map((row, i) => (
                    <TableRow
                      handleInputChange={handleInputChange}
                      handleStringInputChange={handleStringInputChange}
                      formValues={{
                        ...formValues,
                        '301': formValues['120'],
                        '303': formValues['301'] - formValues['302']
                      }}
                      label={row.label}
                      name={row.name}
                      col2={row.col2}
                      index={i}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <SectionTitle char='K' title='Summary of seller’s transactions' />
              <div className={cx(styles.table, styles.table_60_40)}>
                {[
                  { label: '400. Gross Amount Due to Seller:', name: '400', col2: false },
                  { label: '401. Contract sales price', name: '401', col2: true },
                  { label: '402. Personal property', name: '402', col2: true },
                  { label: '403.', name: '403', col2: true },
                  { label: '404.', name: '404', col2: true },
                  { label: '405.', name: '405', col2: true },
                  { label: '106. City/town taxes', name: '106-city', col2: true },
                  {
                    label: 'Adjustments for items paid by Seller in advance',
                    name: '400_adjustment',
                    col2: false
                  },
                  { label: '406. City/town taxes', name: '406', col2: true },
                  { label: '407. Country taxes', name: '407', col2: true },
                  { label: '408. Assessments', name: '408', col2: true },
                  { label: '409.', name: '409', col2: true },
                  { label: '410.', name: '410', col2: true },
                  { label: '411.', name: '411', col2: true },
                  { label: '412.', name: '412', col2: true },
                  { label: '420. Gross Amount Due to Seller', name: '420', col2: true }
                ].map(row => (
                  <TableRow
                    handleInputChange={handleInputChange}
                    handleStringInputChange={handleStringInputChange}
                    formValues={{ ...formValues, '420': calculateSum(401, 412) }}
                    label={row.label}
                    name={row.name}
                    col2={row.col2}
                  />
                ))}
              </div>

              <div className={cx(styles.table, styles.table_60_40)}>
                {[
                  { label: '500. REDUCTIONS IN AMOUNT DUE TO SELLER', name: '500', col2: false },
                  { label: '501. Excess deposit (see instructions)', name: '501', col2: true },
                  {
                    label: '502. Settlement charges to seller (line 1400)',
                    name: '502',
                    col2: true
                  },
                  { label: '503. Existing loan(s) taken subject to', name: '503', col2: true },
                  { label: '504. Payoff of first mortgage loan', name: '504', col2: true },
                  { label: '505. Payoff of second mortgage loan', name: '505', col2: true },
                  { label: '506.', name: '506', col2: true },
                  {
                    label: '507.',
                    name: '507',
                    col2: false
                  },
                  { label: '508.', name: '508', col2: true },
                  { label: '509.', name: '509', col2: true },
                  {
                    label: 'Adjustments for items unpaid by Seller',
                    name: '500_adjustment',
                    col2: true
                  },
                  { label: '510. City/town taxes', name: '510', col2: true },
                  { label: '511. County taxes', name: '511', col2: true },
                  { label: '512. Assessments', name: '512', col2: true },
                  { label: '513.', name: '513', col2: true },
                  { label: '514.', name: '514', col2: true },
                  { label: '515.', name: '515', col2: true },
                  { label: '516.', name: '516', col2: true },
                  { label: '517.', name: '517', col2: true },
                  { label: '518.', name: '518', col2: true },
                  { label: '519.', name: '519', col2: true },
                  { label: '520. Total Reduction Amount Due Seller', name: '520', col2: true }
                ].map(row => (
                  <TableRow
                    handleInputChange={handleInputChange}
                    handleStringInputChange={handleStringInputChange}
                    formValues={{ ...formValues, '520': calculateSum(501, 519) }}
                    label={row.label}
                    name={row.name}
                    col2={row.col2}
                  />
                ))}
              </div>

              <div className={cx(styles.table, styles.table_60_40)}>
                {[
                  { label: '600. CASH AT SETTLEMENT TO/FROM SELLER', name: '600', col2: false },
                  { label: '601. Gross amount due to Seller (line 420)', name: '601', col2: true },
                  {
                    label: '602. Less reductions in amount due Seller (line 520)',
                    name: '602',
                    col2: true
                  },
                  { label: '603. Cash', name: '603', col2: true }
                ].map(row => (
                  <TableRow
                    handleInputChange={handleInputChange}
                    handleStringInputChange={handleStringInputChange}
                    formValues={formValues}
                    label={row.label}
                    name={row.name}
                    col2={row.col2}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* section L */}
          <div>
            <SectionTitle char='L' title='Settlement charges' />
            <div className={cx(styles.table, styles.table_60_40)}>
              {[
                { label: '700. Total real estate/broker’s fees', name: '700', col2: false },
                {
                  label: 'Division of commission (line (1700) as follows:',
                  name: '1700',
                  col2: true,
                  col3: true,
                  col2Label: 'Paid from Brrower’s Funds at Settlement',
                  col3Label: 'Paid from Seller’s Funds at Settlement'
                },
                {
                  labelArray: [
                    { type: 'text', value: '701. $' },
                    { type: 'input', name: '701.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '701.02' }
                  ],
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '702. $' },
                    { type: 'input', name: '702.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '702.02' }
                  ],
                  col2: true,
                  col3: true
                },
                {
                  label: '703. Commission paid at Settlement',
                  name: '703',
                  col2: true,
                  col3: true
                },
                { label: '704.', name: '704', col2: true, col3: true }
              ].map((row, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  col2Label={row?.col2Label}
                  col3Label={row?.col3Label}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '800. ITEMS PAYABLE IN CONNECTION WITH LOAN',
                  name: '800',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '801. Your origination charge' },
                    { type: 'input', name: '801.01' },
                    { type: 'text', value: '(From GFE #1)' }
                  ],
                  name: '801',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    {
                      type: 'text',
                      value:
                        '802. Your credit or charge (points) for the specific interest rate chosen $'
                    },
                    { type: 'input', name: '802.01' },
                    { type: 'text', value: '(From GFE #1)' }
                  ],
                  name: '802',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '803. Your adjusted origination charges' },
                    { type: 'input', name: '803.01' },
                    { type: 'text', value: '(From GFE #A)' }
                  ],
                  name: '803',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '804. Appraisal fee' },
                    { type: 'input', name: '804.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '804.02' },
                    { type: 'text', value: '(From GFE #A)' }
                  ],
                  name: '804',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '805. Credit report' },
                    { type: 'input', name: '805.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '805.02' },
                    { type: 'text', value: '(From GFE #3)' }
                  ],
                  name: '805',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '806. Tax service ' },
                    { type: 'input', name: '806.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '806.02' }
                  ],
                  name: '806',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '807. Flood certification' },
                    { type: 'input', name: '807.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '807.02' },
                    { type: 'text', value: '(From GFE #3)' }
                  ],
                  name: '807',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '808.' },
                    { type: 'input', name: '808.01' }
                  ],
                  name: '808',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '809.' },
                    { type: 'input', name: '809.01' }
                  ],
                  name: '809',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '810.' },
                    { type: 'input', name: '810.01' }
                  ],
                  name: '810',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '811.' },
                    { type: 'input', name: '811.01' }
                  ],
                  name: '811',
                  col2: true,
                  col3: true
                }
              ].map((row, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '900. Items Required by Lender to be Paid in Advance',
                  name: '900',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '901. Daily interest charges' },
                    { type: 'text', value: 'From' },

                    { type: 'input', name: '901.01' },
                    { type: 'text', value: 'to' },
                    { type: 'input', name: '901.02' },
                    { type: 'text', value: '@ $ ' },
                    { type: 'input', name: '901.03' },

                    { type: 'text', value: '(From GFE #10)' }
                  ],
                  name: '901',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '902. Mortgage insurance premimum' },

                    { type: 'text', value: 'For' },
                    { type: 'input', name: '902.01' },
                    { type: 'text', value: 'Months to' },
                    { type: 'input', name: '902.02' },

                    { type: 'text', value: '(From GFE #3)' }
                  ],
                  name: '902',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '903. Homeowner’s insurance ' },
                    { type: 'text', value: 'For' },
                    { type: 'input', name: '903.01' },
                    { type: 'text', value: 'Years to' },
                    { type: 'input', name: '903.02' },

                    { type: 'text', value: '(From GFE #11)' }
                  ],
                  name: '903',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '904.' },
                    { type: 'input', name: '904.01' }
                  ],
                  name: '904',
                  col2: true,
                  col3: true
                }
              ].map((row, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '1000. Reserves deposited with lender',
                  name: '900',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '1001. Initial deposit for your escrow account' },
                    { type: 'input', name: '1001.01' },

                    { type: 'text', value: '(From GFE #9)' }
                  ],
                  name: '1001',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1002. Homeowner’s insurance' },

                    { type: 'text', value: 'Months @ $ ' },
                    { type: 'input', name: '1002.01' },
                    { type: 'text', value: 'per month $ ' },
                    { type: 'input', name: '1002.02' }
                  ],
                  name: '1002',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1003. Mortgage insurance' },

                    { type: 'text', value: 'Months @ $ ' },
                    { type: 'input', name: '1003.01' },
                    { type: 'text', value: 'per month $ ' },
                    { type: 'input', name: '1003.02' }
                  ],
                  name: '1003',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1004. Property Taxes' },

                    { type: 'text', value: 'Months @ $ ' },
                    { type: 'input', name: '1004.01' },
                    { type: 'text', value: 'per month $ ' },
                    { type: 'input', name: '1004.02' }
                  ],
                  name: '1004',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1005.' },
                    { type: 'input', name: '1005.01' },

                    { type: 'text', value: 'Months @ $ ' },
                    { type: 'input', name: '1005.02' },
                    { type: 'text', value: 'per month $ ' },
                    { type: 'input', name: '1005.03' }
                  ],
                  name: '1005',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1006.' },
                    { type: 'input', name: '1006.01' },
                    { type: 'text', value: 'Months @ $ ' },
                    { type: 'input', name: '1006.02' },
                    { type: 'text', value: 'per month $ ' },
                    { type: 'input', name: '1006.03' }
                  ],
                  name: '1006',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1007. Aggregate adjustment ' },

                    { type: 'input', name: '1007.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1007.02' }
                  ],
                  name: '1007',
                  col2: true,
                  col3: true
                }
              ].map((row, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '1100. Title Charges',
                  name: '1100',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '1101. Title services and lender’s title insurance' },
                    { type: 'input', name: '1101.01' },

                    { type: 'text', value: '(From GFE #4)' }
                  ],
                  name: '1101',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1102. Settlement or closing fee' },

                    { type: 'input', name: '1102.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1102.02' }
                  ],
                  name: '1102',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1103. Owner’s title Insurance' },

                    { type: 'input', name: '1103.01' },
                    { type: 'text', value: '(From GFE #5)' }
                  ],
                  name: '1103',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1104. Lender’s tittle insurance' },

                    { type: 'input', name: '1104.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1104.02' }
                  ],
                  name: '1104',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1105. Lender’s title policy limit' },

                    { type: 'input', name: '1105.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1105.02' }
                  ],
                  name: '1105',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1106. Owner’s title policy limit.' },

                    { type: 'input', name: '1106.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1106.02' }
                  ],
                  name: '1106',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    {
                      type: 'text',
                      value: '1107. Agent’s portion of the total title insurance premium'
                    },

                    { type: 'input', name: '1107.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1107.02' }
                  ],
                  name: '1107',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    {
                      type: 'text',
                      value: '1108. Underwriter’s portion of the total title insurance premium to '
                    },

                    { type: 'input', name: '1108.01' },
                    { type: 'text', value: ' $ ' },
                    { type: 'input', name: '1108.02' }
                  ],
                  name: '1108',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1109.' },

                    { type: 'input', name: '1109.01' }
                  ],
                  name: '1109',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1110.' },

                    { type: 'input', name: '1110.01' }
                  ],
                  name: '1110',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1111.' },

                    { type: 'input', name: '1111.01' }
                  ],
                  name: '1111',
                  col2: true,
                  col3: true
                },
                ,
              ].map((row: any, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '1200. Government Recording and transfer charges',
                  name: '1200',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '1201. Government recording charges' },
                    { type: 'input', name: '1201.01' },

                    { type: 'text', value: '(From GFE #7)' }
                  ],
                  name: '1201',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1202. Deed $ ' },

                    { type: 'input', name: '1202.01' },
                    { type: 'text', value: 'Mortgage $ ' },
                    { type: 'input', name: '1202.02' },

                    { type: 'text', value: ' Release $ ' },
                    { type: 'input', name: '1202.03' }
                  ],
                  name: '1202',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1203. Transfer taxes' },

                    { type: 'input', name: '1203.01' },
                    { type: 'text', value: '(From GFE #8)' }
                  ],
                  name: '1203',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1204. City/County tax/stamps ' },
                    { type: 'text', value: 'Deed $ ' },

                    { type: 'input', name: '1204.01' },
                    { type: 'text', value: 'Mortgage $ ' },

                    { type: 'input', name: '1204.02' }
                  ],
                  name: '1204',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1205. State tax/stamps' },
                    { type: 'text', value: 'Deed $ ' },

                    { type: 'input', name: '1205.01' },
                    { type: 'text', value: 'Mortgage $ ' },

                    { type: 'input', name: '1205.02' }
                  ],
                  name: '1205',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1206.' },

                    { type: 'input', name: '1206.01' }
                  ],
                  name: '1206',
                  col2: true,
                  col3: true
                }
              ].map((row: any, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: '1300. Additional Settlement Charges',
                  name: '1300',
                  col2: false,
                  col3: false
                },
                {
                  labelArray: [
                    { type: 'text', value: '1301. Required services that you can shop for' },
                    { type: 'input', name: '1301.01' },

                    { type: 'text', value: '(From GFE #6)' }
                  ],
                  name: '1301',
                  col2: true,
                  col3: true
                },

                {
                  labelArray: [
                    { type: 'text', value: '1302.' },

                    { type: 'input', name: '1302.01' }
                  ],
                  name: '1302',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1303.' },

                    { type: 'input', name: '1303.01' }
                  ],
                  name: '1303',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1304.' },

                    { type: 'input', name: '1304.01' }
                  ],
                  name: '1304',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: '1305.' },

                    { type: 'input', name: '1305.01' }
                  ],
                  name: '1305',
                  col2: true,
                  col3: true
                }
              ].map((row: any, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label:
                    '1400. Total settlement charges (Enter on lines 103, section J and 502, section K)',
                  name: '1400',
                  col2: false,
                  col3: false
                }
              ].map((row: any, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  index={i}
                />
              ))}
            </div>

            <div className={cx(styles.table, styles.table_60_40)} style={{ marginTop: '20px' }}>
              {[
                {
                  label: 'Comparison of good faith estimate (GFE) and HUD-1 Charges',
                  name: 'Comparison',
                  col2: true,
                  col3: true,
                  col2Label: 'Good faith estimate',
                  col3Label: 'HUD-1'
                },
                {
                  labelArray: [
                    { type: 'text', value: 'Charges that cannot increase' },
                    { type: 'input', name: 'Comparison1.01' },

                    { type: 'text', value: 'HUD-1 Line Number' }
                  ],
                  name: 'Comparison1',
                  col2: true,
                  col3: true
                },

                {
                  labelArray: [
                    { type: 'text', value: 'Our origination charge' },
                    { type: 'input', name: 'Comparison2.01' },

                    { type: 'text', value: '#801' }
                  ],
                  name: 'Comparison2',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    {
                      type: 'text',
                      value:
                        'Our your credit or charge (points) for the specific interest rate chosen'
                    },
                    { type: 'input', name: 'Comparison3.01' },

                    { type: 'text', value: '#802' }
                  ],
                  name: 'Comparison3',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: 'Your adjusted origination charges ' },
                    { type: 'input', name: 'Comparison4.01' },

                    { type: 'text', value: '#803' }
                  ],
                  name: 'Comparison4',
                  col2: true,
                  col3: true
                },
                {
                  labelArray: [
                    { type: 'text', value: 'Transfer taxes Charges' },
                    { type: 'input', name: 'Comparison5.01' },

                    { type: 'text', value: '#1203' }
                  ],
                  name: 'Comparison5',
                  col2: true,
                  col3: true
                }
              ].map((row: any, i) => (
                <TableRow
                  handleInputChange={handleInputChange}
                  handleStringInputChange={handleStringInputChange}
                  formValues={formValues}
                  label={row.label}
                  name={row.name}
                  col2={row.col2}
                  col3={row?.col3}
                  labelArray={row?.labelArray}
                  col2Label={row?.col2Label}
                  col3Label={row?.col3Label}
                  index={i}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HUD;
