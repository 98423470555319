import React, { useState, useContext, useEffect } from 'react';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './drip-system.module.scss';
import { useNavigate } from 'react-router-dom';
import Template from '../template';
import { useMutation, useQuery } from 'react-query';
import AppConfig from '../../config';
import Storage from '../../utils/local-storage';
import axios from 'axios';
import SwitchButton from '../../components/switch-button/SwitchButton';
import { ProjectContext } from '../../context/ProjectContext';
import DripSystemFilters from '../../components/drip-system-filters';
import { deepCopy } from '../../utils/deep-copy';
import { cx } from '../../utils/class-name-service';
import { props } from './model';

const DripSystem: React.FC<props> = ({
  setEnableEdit,
  enableEdit,
  setShowCreateTemplate,
  showCreateTemplate
}) => {
  const { setDripSystemDetails } = useContext(ProjectContext);
  const [triggerType, setTriggerType] = useState('');
  const [scheduledforType, setScheduledforType] = useState('');
  const [automationType, setAutomationType] = useState('');
  const [selecteBusinessList, setSelecteBusinessList] = useState('');
  const [campaignList, setCampaignList] = useState<any>();
  const [originalCampaignList, setOriginalCampaignList] = useState<any>();

  const [value, setValue] = React.useState(0);
  const [filterValue, setFilterValue] = useState('');

  const navigate = useNavigate();
  const storage = new Storage();
  const projectId = storage.get('projectId');
  const BASE_URL = AppConfig.API_BASE_URL;
  const getCampaignList = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/emailAutomation/${storage.get('projectId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };
  const {
    data: campaignList1,
    status: campaignListStatus,
    refetch
  } = useQuery(`campaignList`, getCampaignList, {
    onSuccess: res => {
      setOriginalCampaignList(res);
      setCampaignList(res);
    }
  });

  //update status
  const updateStatusAPI = async (id: any) => {
    const config = {
      method: 'patch',
      url: `${BASE_URL}/v1/emailAutomation/updateStatus/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };

    return await axios(config);
  };
  const { status: mutationStatus, mutateAsync: updateStatus } = useMutation(updateStatusAPI, {
    onSuccess: res => {
      refetch();
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const [teamList, setTeamList] = useState<
  //   {
  //     campaignName: string;
  //     businessList: string;
  //     createdOn: string;
  //     status: string;
  //   }[]
  // >([]);
  const resetFilter = () => {
    setAutomationType('');
    setScheduledforType('');
    setTriggerType('');
    setSelecteBusinessList('');
  };

  useEffect(() => {
    if (originalCampaignList?.length) {
      let CampaignListCopy: any = deepCopy(originalCampaignList);

      if (triggerType) {
        CampaignListCopy = CampaignListCopy.filter(
          (item: any) => item['triggerType'] === triggerType
        );
      }
      if (scheduledforType) {
        if (scheduledforType === 'Immediately') {
          CampaignListCopy = CampaignListCopy.filter((item: any) => item['isImmediately'] === true);
        } else {
          CampaignListCopy = CampaignListCopy.filter(
            (item: any) => item['offsetDays'] === scheduledforType
          );
        }
      }
      if (automationType !== '') {
        CampaignListCopy = CampaignListCopy.filter(
          (item: any) => item['active'] === automationType
        );
      }
      if (selecteBusinessList) {
        CampaignListCopy = CampaignListCopy.filter(
          (item: any) => item['savedBusinessList']['_id'] === selecteBusinessList
        );
      }
      if (filterValue !== '') {
        if (
          filterValue !== 'createdAt' &&
          filterValue !== 'businessName' &&
          filterValue !== 'offsetDays'
        ) {
          CampaignListCopy = CampaignListCopy.slice().sort((a: any, b: any) => {
            if (a[filterValue] < b[filterValue]) {
              return -1;
            }
            if (a[filterValue] > b[filterValue]) {
              return 1;
            }
            return 0;
          });
        }
        if (filterValue === 'businessName') {
          CampaignListCopy = CampaignListCopy.slice().sort((a: any, b: any) => {
            if (a['savedBusinessList']['list_name'] < b['savedBusinessList']['list_name']) {
              return -1;
            }
            if (a['savedBusinessList']['list_name'] > b['savedBusinessList']['list_name']) {
              return 1;
            }
            return 0;
          });
        }
        if (filterValue === 'createdAt') {
          CampaignListCopy = CampaignListCopy.sort(
            (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        }
        if (filterValue === 'offsetDays') {
          CampaignListCopy = CampaignListCopy.sort((a: any, b: any) => {
            if (a.isImmediately && !b.isImmediately) {
              return -1; // a comes before b
            } else if (!a.isImmediately && b.isImmediately) {
              return 1; // b comes before a
            } else {
              return a.offsetDays - b.offsetDays; // sort by offsetDays
            }
          });
        }
      }
      setCampaignList(CampaignListCopy);
    }
  }, [
    triggerType,
    scheduledforType,
    automationType,
    selecteBusinessList,
    campaignList1,
    filterValue
  ]);
  const formattedDate = (ISODate: any) => {
    const date = new Date(ISODate);
    const options: any = {
      timeZone: 'Asia/Kolkata',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: 'numeric',
      hour12: true,
      hourCycle: 'h11'
    };
    const istDate = date
      .toLocaleString('en-US', options)
      .replace(/(^|\W)[a-z]/g, m => m.toUpperCase())
      .replace(/PM|AM/g, m => m.toLowerCase());
    return istDate;
  };

  const capitalize = (s: String) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const returnComponent = () => {
    switch (value) {
      case 0:
        return (
          <>
            {campaignListStatus === 'success' && (
              <div className={styles.page_wrapper}>
                <div className={styles.page_heading}>
                  <div
                    className={styles.circular_hover}
                    onClick={() => {
                      navigate(`/dashboard/${projectId}`);
                    }}
                  >
                    <img src='/back-arrow.svg' alt='Back' width='20' height='20' />
                  </div>
                  <h5>Create a new campaign</h5>
                </div>
                <div
                  className={styles.create_new_campaign}
                  onClick={() => {
                    setDripSystemDetails({});
                    navigate('/create-automation-form');
                  }}
                >
                  <img
                    src='/message_icon_email.svg'
                    alt='message_icon_email'
                    width={55}
                    height={55}
                  />
                  <div className={styles.create_new_campaign_text}>Email Automation</div>
                  <div className={styles.create_new_campaign_description}>
                    Send automated follow-up emails with workflows and rules
                  </div>
                </div>
                {/* filters */}

                <div
                  className={styles.page_heading}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ width: '100%' }}>All campaigns</div>
                  {(triggerType ||
                    scheduledforType ||
                    automationType !== '' ||
                    selecteBusinessList) && (
                    <div
                      className={styles.reset_filter}
                      onClick={() => {
                        resetFilter();
                      }}
                      style={{ width: '100%' }}
                    >
                      Reset Filter
                    </div>
                  )}
                  <DripSystemFilters
                    triggerType={triggerType}
                    setTriggerType={setTriggerType}
                    scheduledforType={scheduledforType}
                    setScheduledforType={setScheduledforType}
                    automationType={automationType}
                    setAutomationType={setAutomationType}
                    selecteBusinessList={selecteBusinessList}
                    setSelecteBusinessList={setSelecteBusinessList}
                  />
                </div>
                {campaignList?.length === 0 ? (
                  <>
                    <div className={styles.no_data}>
                      <img src='/campaign.svg' alt='no_data' />
                      <div className={styles.no_campaign_heading}>
                        Let's create your first campaign!
                      </div>
                    </div>
                  </>
                ) : (
                  <table className={styles.table} cellSpacing='0' cellPadding='12'>
                    <thead className={styles.table_header}>
                      <tr>
                        <th
                          className={styles.table_header_user}
                          onClick={() => setFilterValue(filterValue === 'name' ? '' : 'name')}
                          style={{ borderTopLeftRadius: '8px' }}
                        >
                          Campaign name
                          <img
                            src='/images/filterArrow.svg'
                            alt=''
                            className={
                              filterValue === 'name' ? styles.img_selected : styles.img_arrow
                            }
                          />
                        </th>
                        <th
                          className={styles.table_header_status}
                          onClick={() =>
                            setFilterValue(filterValue === 'businessName' ? '' : 'businessName')
                          }
                        >
                          Business list
                          <img
                            src='/images/filterArrow.svg'
                            alt=''
                            className={
                              filterValue === 'businessName'
                                ? styles.img_selected
                                : styles.img_arrow
                            }
                          />
                        </th>
                        <th
                          className={styles.table_header_email}
                          onClick={() =>
                            setFilterValue(filterValue === 'createdAt' ? '' : 'createdAt')
                          }
                        >
                          Created on
                          <img
                            src='/images/filterArrow.svg'
                            alt=''
                            className={
                              filterValue === 'createdAt' ? styles.img_selected : styles.img_arrow
                            }
                          />
                        </th>
                        <th
                          className={styles.table_header_email}
                          onClick={() =>
                            setFilterValue(filterValue === 'triggerType' ? '' : 'triggerType')
                          }
                        >
                          Status
                          <img
                            src='/images/filterArrow.svg'
                            alt=''
                            className={
                              filterValue === 'triggerType' ? styles.img_selected : styles.img_arrow
                            }
                          />
                        </th>
                        <th
                          className={styles.table_header_email}
                          onClick={() =>
                            setFilterValue(filterValue === 'offsetDays' ? '' : 'offsetDays')
                          }
                        >
                          Scheduled
                          <img
                            src='/images/filterArrow.svg'
                            alt=''
                            className={
                              filterValue === 'offsetDays' ? styles.img_selected : styles.img_arrow
                            }
                          />
                        </th>
                        <th
                          className={styles.table_header_role}
                          style={{ borderTopRightRadius: '8px' }}
                        >
                          Active / Inactive
                        </th>
                      </tr>
                    </thead>
                    <tbody className={styles.table_body} style={{ minHeight: '50px' }}>
                      {campaignList?.map((element: any, index: any) => {
                        return (
                          <tr
                            className={styles.table_body_row}

                            // onMouseLeave={hideRowAction}
                            // onMouseEnter={() => showRowAction(index)}
                          >
                            <td
                              style={{ color: '#2A72DD' }}
                              onClick={() =>
                                (window.location.href = `/view-automation-form/${element._id}`)
                              }
                            >
                              {element.name}
                            </td>
                            <td
                              onClick={() =>
                                (window.location.href = `/view-automation-form/${element._id}`)
                              }
                            >
                              {element.savedBusinessList?.list_name}
                            </td>
                            <td
                              onClick={() =>
                                (window.location.href = `/view-automation-form/${element._id}`)
                              }
                            >
                              {formattedDate(element.createdAt)}
                            </td>
                            <td
                              onClick={() =>
                                (window.location.href = `/view-automation-form/${element._id}`)
                              }
                            >
                              {element.triggerType}
                            </td>
                            <td
                              onClick={() =>
                                (window.location.href = `/view-automation-form/${element._id}`)
                              }
                            >
                              {element.isImmediately ? 'Immediately' : `${element.offsetDays} days`}
                            </td>
                            <td
                            // style={{
                            //   display: 'flex',
                            //   justifyContent: 'center',
                            //   alignItems: 'center',
                            //   width: '120px',
                            //   position: 'relative'
                            // }}
                            >
                              <span>
                                <SwitchButton
                                  isSwitchDefaultChecked={element.active}
                                  handleSwitch={() => {
                                    updateStatus(element._id);
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </>
        );
      case 1:
        return (
          <div className={styles.template}>
            <Template
              comp={'drip'}
              setEnableEdit={setEnableEdit}
              enableEdit={enableEdit}
              setShowCreateTemplate={setShowCreateTemplate}
              showCreateTemplate={showCreateTemplate}
            />
          </div>
        );
      // case 2:
      //   return <div>Analytics</div>;
      default:
        return <div>Home</div>;
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          height: '60px'
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={{
            height: '60px',
            width: '100%',
            backgroundColor: ' #FFFFFF;',
            paddingTop: '10px'
          }}
        >
          <Tab label={<p className={styles.side_nav}>Home</p>} />
          <Tab label={<p className={styles.side_nav}>Templates</p>} />
          {/* <Tab label={<p className={styles.side_nav}>Analytics</p>} /> */}
        </Tabs>
      </Box>

      <div className={styles.main_container}>{returnComponent()}</div>
    </>
  );
};

export default DripSystem;
