import React, { useEffect, useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { props } from './model';
import { useNavigate, useParams } from 'react-router-dom';
import CustomisedTextField from '../../../components/form-elements/customised-text-field/index';
import WrappedButton from '../../../components/form-elements/buttons/index';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import styles from './create-campaign-form.module.scss';
import Template from '../../../screens/template';
import CustomSelectField from '../../../components/form-elements/customised-select-field';
import { DaysToSentEmailOptions, PersonHasOptions } from '../../../constants/dropDown-constanst';
import SwitchButton from '../../../components/switch-button/SwitchButton';
import AppConfig from '../../../config';
import Storage from '../../../utils/local-storage';
import axios from 'axios';
import { useMutation } from 'react-query';
import HttpHelper from '../../../utils/http-helper';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../context/ProjectContext';
import HolidayCalender from '../../../components/modals/drip-system-calender';

const dateConverter = (dates: any) => {
  const arrayOfDateString = dates;
  if (arrayOfDateString?.length > 0) {
    const convertedDate = arrayOfDateString.map((item: any) => {
      let date = new Date(item);

      let options: any = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    });
    return convertedDate;
  } else {
    return [];
  }
};
const CreateCampaignForm: React.FC<props> = ({ activeStep, setActiveStep, Step, setStep }) => {
  const { campaignId } = useParams();
  const { dripSystemDetails, setDripSystemDetails } = useContext(ProjectContext);
  const [isImmediately, setIsImmediately] = React.useState(
    dripSystemDetails.isImmediately ? dripSystemDetails.isImmediately : false
  );
  const [satSate, setSatState] = useState(dripSystemDetails.isSaturdayAllowed);
  const [sunState, setSunState] = useState(dripSystemDetails.isSundayAllowed);
  const [step1Data, setStep1Data] = useState<any>({
    name: dripSystemDetails?.name,
    savedBusinessList: dripSystemDetails?.savedBusinessList?._id
      ? dripSystemDetails?.savedBusinessList?._id
      : dripSystemDetails?.savedBusinessList,
    triggerType: dripSystemDetails?.triggerType
  });
  const [step2Data, setStep2Data] = useState<any>(dripSystemDetails.template);
  const [step3Data, setStep3Data] = useState<any>();
  const [savedList, setsavedList] = useState([]);
  const [date, setDate] = useState(dripSystemDetails.selectedHolidaysDate);
  const [showModal, setShowModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { getRequest } = new HttpHelper();

  const handleSaturdayStateChange = (event: any) => {
    setSatState(event.target.checked);
  };

  const handleSundayStateChange = (event: any) => {
    setSunState(event.target.checked);
  };

  const postData = async (data: any) => {
    data.name = data.name.trim();
    const requestData = JSON.stringify({
      ...data
    });

    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/emailAutomation/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    const res = await axios(config);
  };
  const { status: mutationStatus, mutateAsync: addData } = useMutation(postData, {
    onSuccess: () => {
      setDripSystemDetails({});
      navigate('/email-automation');
      enqueueSnackbar('Email automation created', { variant: 'success', header: 'Success' });
    },
    onError: (err: any) => {
      let message = err.response.data.message;

      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  const emailAutomationUpdate = async (data: any) => {
    data.name = data.name.trim();
    const requestData = JSON.stringify({
      ...data
    });

    const config = {
      method: 'patch',
      url: `${BASE_URL}/v1/emailAutomation/update/${campaignId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    const res = await axios(config);
  };
  const { status: emailAutomationUpdateStatus, mutateAsync: emailAutomationUpdateChanges } =
    useMutation(emailAutomationUpdate, {
      onSuccess: () => {
        setDripSystemDetails({});
        navigate('/email-automation');
        enqueueSnackbar('Email automation updated', { variant: 'success', header: 'Success' });
      },
      onError: (err: any) => {
        let message = err.response.data.message;

        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      }
    });

  const onDataSubmit = (data: any) => {
    const emailAutomationFormData = {
      ...step1Data,
      template: step2Data,
      project: storage.get('projectId'),
      user: storage.get('userId'),
      isImmediately: isImmediately,
      offsetDays: !isImmediately && data.howManyDays,
      isSundayAllowed: sunState,
      isSaturdayAllowed: satSate,
      selectedHolidaysDate: date
    };
    setDripSystemDetails({ ...dripSystemDetails, ...emailAutomationFormData });
    if (!campaignId) addData(emailAutomationFormData);
    else emailAutomationUpdateChanges(emailAutomationFormData);
  };

  const getSavedList = async () => {
    // Get all saved lists for project
    const url = `${BASE_URL}/v1/savedbusinesslist/${storage.get('projectId')}`;
    const { data } = await getRequest({ url });
    if (data.length <= 0) {
    } else {
      const bListArray: any = [];
      data.forEach((el: any) => {
        const obj: any = {};
        obj.value = el._id;
        obj.label = el.list_name;
        obj.count = el.business_ids.length;
        bListArray.push(obj);
      });
      setsavedList(bListArray);
    }
  };
  useEffect(() => {
    (async () => {
      await getSavedList();
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  const validationSchema = yup.object({
    name: yup.string().trim().required('Campaign name is required'),
    savedBusinessList: yup.string().required('Business list is required'),

    triggerType: yup.string().required('Status is required')
  });
  useEffect(() => {
    setDripSystemDetails({ ...dripSystemDetails, step2Data });
  }, [step2Data]);

  const returnForm = () => {
    switch (Step) {
      case 0:
        return (
          <div className={styles.form_container}>
            <div className={styles.form_title}>Choose email recipients</div>

            <Formik
              initialValues={{
                name: dripSystemDetails?.name,
                savedBusinessList: dripSystemDetails?.savedBusinessList?._id
                  ? dripSystemDetails?.savedBusinessList?._id
                  : dripSystemDetails?.savedBusinessList,
                triggerType: dripSystemDetails?.triggerType
              }}
              onSubmit={data => {
                if (activeStep <= 1) setActiveStep(1);
                setStep(1);
                setStep1Data(data);
                if (data.triggerType === 'Not Opened') setIsImmediately(false);
                setDripSystemDetails({ ...dripSystemDetails, ...data });
              }}
              validationSchema={validationSchema}
            >
              {props => (
                <Form className={styles.form}>
                  <div className={styles.form_field}>
                    <Field
                      name='name'
                      placeholder={'Enter campaign name'}
                      // title={'Campaign Name'}
                      type={'text'}
                      component={CustomisedTextField}
                      required={true}
                      // label={'Enter campaign name'}
                    />
                  </div>

                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name={`savedBusinessList`}
                        placeholder='Choose business list'
                        options={savedList}
                        component={CustomSelectField}
                      ></Field>
                    </div>

                    <div className={styles.single_field}>
                      <Field
                        name={`triggerType`}
                        placeholder='Choose status'
                        options={PersonHasOptions}
                        component={CustomSelectField}
                      ></Field>
                    </div>
                  </div>

                  <div className={styles.buttons_wrapper}></div>

                  <div className={styles.add_project_footer}>
                    <div className={styles.button_wrapper}>
                      <WrappedButton
                        btnText='Next'
                        type='submit'
                        btnType={BTN_PRIMARY}
                        className={styles.submit_button}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        );
      case 1:
        return (
          <div className={styles.form} style={{ marginBottom: '4rem', width: '100%' }}>
            <Template
              comp={'create-campaign'}
              setStep2Data={setStep2Data}
              setEnableEdit={setEnableEdit}
              enableEdit={enableEdit}
              setShowCreateTemplate={setShowCreateTemplate}
              showCreateTemplate={showCreateTemplate}
            />
            <div className={styles.add_project_footer}>
              <div className={styles.button_wrapper}>
                <WrappedButton
                  btnText='Next'
                  // type='submit'
                  onClick={() => {
                    if (activeStep <= 2) setActiveStep(2);
                    setStep(2);
                  }}
                  btnType={BTN_PRIMARY}
                  className={styles.submit_button}
                  isDisabled={enableEdit || showCreateTemplate}
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <>
            <Formik
              initialValues={{
                howManyDays: dripSystemDetails?.offsetDays ? dripSystemDetails?.offsetDays : 1
              }}
              onSubmit={data => {
                onDataSubmit(data);
              }}
            >
              <Form>
                <div className={styles.form_container}>
                  <div className={styles.form_title}>Schedule an Email</div>
                  <div className={styles.form_description}>
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry */}
                  </div>

                  {step1Data.triggerType !== 'Not Opened' && (
                    <div className={styles.cards_wrapper}>
                      <div className={styles.card}>
                        <div className={styles.card_title_radio}>
                          <div className={styles.card_title}>Send Immediately</div>
                          <div className={styles.radio_button}>
                            <input
                              type='radio'
                              onChange={() => setIsImmediately(true)}
                              checked={isImmediately === true}
                            />
                          </div>
                        </div>

                        <div className={styles.card_description}>
                          Send automated emails right after the action occurs.
                        </div>
                      </div>

                      <div className={styles.card}>
                        <div className={styles.card_title_radio}>
                          <div className={styles.card_title}>Schedule for later</div>
                          <div className={styles.radio_button}>
                            <input
                              type='radio'
                              onChange={() => setIsImmediately(false)}
                              checked={isImmediately === false}
                            />
                          </div>
                        </div>

                        <div className={styles.card_description}>
                          Will wait for selected days after the action occurs.
                        </div>
                      </div>
                    </div>
                  )}

                  {isImmediately === false ? (
                    <div className={styles.form_schedule_later}>
                      <div className={styles.single_field}>
                        <Field
                          name={`howManyDays`}
                          placeholder={'How many days'}
                          component={CustomSelectField}
                          options={DaysToSentEmailOptions}
                        ></Field>
                      </div>

                      <div>
                        <div className={styles.form_title}>Send on Weekend</div>
                        <div className={styles.form_description}>
                          If turned off, emails scheduled for these days will be sent on next
                          available day.
                        </div>
                        <div className={styles.switch_button}>
                          <div className={styles.switch_button_desc}>Saturday</div>
                          <SwitchButton
                            isSwitchDefaultChecked={satSate}
                            handleSwitch={handleSaturdayStateChange}
                          />
                        </div>
                        <div className={styles.switch_button}>
                          <div className={styles.switch_button_desc}>Sunday</div>
                          <SwitchButton
                            isSwitchDefaultChecked={sunState}
                            handleSwitch={handleSundayStateChange}
                          />
                        </div>
                      </div>

                      <div>
                        <div className={styles.form_title}>Select Holidays</div>
                        <div className={styles.form_description}>
                          Automated emails will not be sent on these dates.
                        </div>
                        <div className={styles.select_dates}>
                          <div
                            style={{
                              color: '#646D72',
                              marginTop: '10px',
                              display: 'flex',
                              gap: '8px',
                              flexWrap: 'wrap'
                            }}
                          >
                            {date?.length > 0
                              ? dateConverter(date).map((item: any) => (
                                  <>
                                    <div className={styles.selectedDate}>{item}</div>
                                  </>
                                ))
                              : 'No dates selected'}
                          </div>
                          <div
                            style={{ color: '#2A72DD', cursor: 'pointer' }}
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            Add Dates
                          </div>
                        </div>
                      </div>

                      <div>{/* Adding a toggle for weekend */}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.add_project_footer}>
                    <div className={styles.button_wrapper}>
                      <WrappedButton
                        btnText={campaignId ? 'Save Changes' : 'Finish'}
                        type='submit'
                        btnType={BTN_PRIMARY}
                        className={styles.submit_button}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
            <HolidayCalender
              showModal={showModal}
              setShowModal={setShowModal}
              setDate={setDate}
              date={date}
            />
          </>
        );
    }
  };

  return <>{returnForm()}</>;
};

export default CreateCampaignForm;
