import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import Profile from '../../containers/organisation-setting/profile';
import styles from './organisation-setting.module.scss';
import Storage from '../../utils/local-storage';
import { useQuery } from 'react-query';
import { avatarGenerator } from '../../utils/avatarGenerator';
import CircularLoader from '../../components/circular-loader';
import AddTeamMemberModal from '../../components/modals/add-team-member';
import { props } from './model';
import DeleteMemberModal from '../../components/modals/delete-member';
import AppConfig from '../../config';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../context/ProjectContext';

const OrganisationSettingScreen: React.FunctionComponent<props> = ({ queryClient }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [showAction, setShowAction] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<Number>(-1);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const BASE_URL = AppConfig.API_BASE_URL;
  const navigate = useNavigate();

  const storage = new Storage();

  const getUserDetails = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/users/${storage.get('userId')}`
    };

    const temp = (await axios(config)).data;
    return temp;
  };

  const { data: userDetails, status: userDetailsStatus } = useQuery(`userdetail`, getUserDetails, {
    refetchOnWindowFocus: true
  });

  const statusTag = (isPasswordSet: Boolean) => {
    switch (isPasswordSet) {
      case true:
        return <div className={styles.status_active}>Active</div>;

      case false:
        return <div className={styles.status_in_progress}>In Progress</div>;

      default:
        break;
    }
  };

  const [teamList, setTeamList] = useState<
    {
      firstName: string;
      lastName: string;
      role: string;
      email: string;
      status: string;
      isPasswordSet: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (userDetailsStatus === 'success') {
      setTeamList(userDetails?.organization[0].team);
    }
  }, [userDetails]);

  const showRowAction = (index: Number) => {
    setShowAction(true);
    setSelectedItemIndex(index);
  };

  const hideRowAction = () => {
    setShowAction(false);
    setSelectedItemIndex(-1);
  };

  const capitalize = (s: String) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  return (
    <>
      <div className={styles.page_container}>
        <div className={styles.page_wrapper}>
          <div className={styles.breadcrumbs}>
            <div
              className={styles.circular_hover}
              onClick={() => {
                navigate('/projects');
              }}
            >
              <img src='/back-arrow.svg' alt='Back' width='24' height='24' />
            </div>
            <div className={styles.page_name}>Settings</div>
          </div>

          <div className={styles.secondary_nav}>
            <div className={styles.secondary_nav_left}>
              <div
                className={
                  activeTab === 'profile'
                    ? styles.secondary_nav_item_active
                    : styles.secondary_nav_item
                }
                onClick={() => setActiveTab('profile')}
              >
                Profile
              </div>
              <div className={styles.secondary_nav_right}>
                <div
                  className={
                    activeTab === 'teamMembers'
                      ? styles.secondary_nav_item_active
                      : styles.secondary_nav_item
                  }
                  onClick={() => setActiveTab('teamMembers')}
                >
                  Team Members
                </div>
              </div>
            </div>
            {activeTab === 'teamMembers' && (
              <button
                className={styles.add_member}
                onClick={() => {
                  setShowModal(true);
                  setSelectedUser({});
                }}
                disabled={storage.get('role') === 'prospector'}
              >
                Add Member
              </button>
            )}
          </div>
          {userDetailsStatus !== 'success' ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CircularLoader />
            </div>
          ) : activeTab === 'profile' ? (
            <>
              <Profile userDetails={userDetails} queryClient={queryClient} />
            </>
          ) : (
            <table className={styles.table} cellSpacing='0' cellPadding='0'>
              <thead className={styles.table_header}>
                <tr>
                  <th
                    className={styles.table_header_checkbox}
                    align='center'
                    style={{ borderTopLeftRadius: '10px' }}
                  >
                    {' '}
                    {/* <input type='checkbox' />{' '} */}
                  </th>
                  <th className={styles.table_header_user}>User</th>
                  <th className={styles.table_header_status}>Status</th>
                  <th className={styles.table_header_email}>Email</th>
                  <th className={styles.table_header_role} style={{ borderTopRightRadius: '10px' }}>
                    Role
                  </th>
                </tr>
              </thead>
              <tbody className={styles.table_body}>
                {teamList.map((element: any, index) => {
                  return (
                    <tr
                      className={styles.table_body_row}
                      onMouseLeave={hideRowAction}
                      onMouseEnter={() => showRowAction(index)}
                    >
                      <td align='center'> {/* <input type='checkbox' />{' '} */}</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={
                            element.profileImage
                              ? `https://reachtenants-dev-assets.s3.amazonaws.com/${element.profileImage}`
                              : avatarGenerator(element.firstName[0], element.lastName[0])
                          }
                          style={{
                            borderRadius: '50%',
                            width: '36px',
                            marginRight: '12px'
                          }}
                        />{' '}
                        {capitalize(element.firstName)} {capitalize(element.lastName)}
                      </td>
                      <td>{statusTag(element.isPasswordSet)}</td>
                      <td>{element.email}</td>
                      <td
                        style={{
                          width: '250px',
                          position: 'relative'
                        }}
                      >
                        <span>{capitalize(element.role)}</span>
                        <span
                          style={{
                            display:
                              selectedItemIndex === index &&
                              showAction &&
                              element.role !== 'owner' &&
                              storage.get('role') !== 'prospector' &&
                              storage.get('userId') !== element.id
                                ? 'inline-block'
                                : 'none',
                            position: 'absolute',
                            left: '85px',
                            top: '20px'
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div
                              className={styles.row_edit}
                              onClick={() => {
                                setShowModal(true);
                                setSelectedUser(element);
                              }}
                            >
                              <img src='images/pencil.svg' alt='' />
                            </div>
                            <div
                              className={styles.row_delete}
                              onClick={() => {
                                setShowDeleteModal(true);
                                setSelectedUser(element);
                              }}
                            >
                              <img src='images/del.svg' alt='' />
                            </div>
                          </div>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <AddTeamMemberModal
        showModal={showModal}
        setShowModal={setShowModal}
        queryClient={queryClient}
        selectedUser={selectedUser}
      />
      <DeleteMemberModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        queryClient={queryClient}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default OrganisationSettingScreen;
