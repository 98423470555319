import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field, FieldArray } from 'formik';
import styles from './source-details.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
const SourceDetialModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  queryClient,
  allSourceData,
  promiseOptions
}) => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  const updateSourcePrice = async (data: any) => {
    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateSourcePrice`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data
    };

    return await axios(config);
  };
  const { status: mutationStatus, mutateAsync: updateSourcePriceHandler } = useMutation(
    updateSourcePrice,
    {
      onSuccess: res => {
        promiseOptions();
        enqueueSnackbar('Source details updated Successfully', {
          variant: 'success',
          header: 'Success'
        });

        setShowModal(false);
      },
      onError: (err: any) => {
        let message = err.response.data.message;
        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      }
    }
  );

  const onSubmit = (data: any) => {};

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Source Details
        </ModalHeader>

        <Formik
          initialValues={{
            customField: allSourceData //other fields
          }}
          onSubmit={values => {
            let data = {
              projectId: `${storage.get('projectId')}`,
              source: values.customField.map((item: any) => {
                return { source: item.label, cost: item.cost };
              })
            };
            updateSourcePriceHandler(data);
          }}
        >
          {({ values, dirty }) => (
            //TODO: Add member form validation
            <Form>
              <div className={styles.form_wrapper}>
                <FieldArray name='customField'>
                  {({ insert, push }) => (
                    <>
                      {values.customField?.map((customField: any, index: any) => (
                        <div key={index}>
                          <div className={styles.two_fields}>
                            <div className={styles.single_field}>
                              <Field
                                name={`customField[${index}].label`}
                                placeholder='Source'
                                component={CustomisedTextField}
                              />
                            </div>

                            <div className={styles.single_field} style={{ position: 'relative' }}>
                              <span style={{ position: 'absolute', top: '60.5%', left: '4px' }}>
                                $
                              </span>
                              <Field
                                name={`customField[${index}].cost`}
                                placeholder='Cost per month'
                                isNumber={true}
                                component={CustomisedTextField}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>

                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText={'Save'}
                    btnType={BTN_PRIMARY}
                    // isDisabled={!(.isValid && props.dirty)}
                    className={styles.submit_button}
                    isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SourceDetialModal;
