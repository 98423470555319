import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import s from '../calculator-container.module.scss';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
const styles = s;
type Option = {
  label: string;
  value: string;
};
interface FormValue {
  name: string;
  date: string;
  range: string;
  rangeType: string;
  countFrom: string;
}

function FormItem({ formArray, handleInputChange, handleStringInputChange, formValues }: any) {
  return (
    <div className={s.form}>
      {formArray.map((element: any) => {
        return (
          <>
            <div className={s.form_item}>
              <p>{element.label}</p>
              {element.type === 'number' && (
                <div className={s.input_container}>
                  <input
                    style={{ width: '265px' }}
                    type='text'
                    onChange={e => handleInputChange(element.name, e.target.value, element.unit)}
                    value={formValues[element.name]}
                  />
                  {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                </div>
              )}
              {element.type === 'string' && (
                <div className={s.input_container}>
                  <input
                    style={{ width: '265px' }}
                    type='text'
                    onChange={e => handleStringInputChange(element.name, e.target.value)}
                    value={formValues[element.name]}
                  />
                  {element.unit != '$' && <div className={s.input_after}>{element.unit}</div>}
                </div>
              )}
              {element.type === 'select' && (
                <div className={s.input_container}>
                  <select
                    onChange={e => handleInputChange(element.name, e.target.value, element.unit)}
                    value={formValues[element.name]}
                  >
                    {element.option.map((el: any) => (
                      <option value={el.id}>{el.value}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
}
const CriticalDateCalculator: React.FunctionComponent<any> = ({ r }) => {
  const [formValues, setFormValues] = useState<any>([]);

  const [option, setOption] = useState('purchaseSale');

  const [selectedRangeType, setSelectedRangeType] = useState<Option[]>([]);

  const rangeTypeOption: any = [
    { label: 'Calendar days after', value: 'Calendar days after' },
    { label: 'Calendar days before', value: 'Calendar days before' },
    { label: 'Business days after', value: 'Business days after' },
    { label: 'Business days before', value: 'Business days before' }
  ];
  const purchaseSaleArray = [
    'Effective date',
    'Closing date',
    'Initial deposit due',
    'Additional deposit due',
    'Deliver due diligence materials by',
    'Title commitment due by',
    'Title commitment received on',
    'Send title objection letter by',
    'Title objection letter sent on',
    'Title objection response due by',
    'Cure title defects by',
    'Date defects cured on',
    'Right to cancel or elect to waive title defect by',
    'Order survey by',
    'Survey due by',
    'Survey received on',
    'Send survey objection letter by',
    'Exercise option to extend due diligence by',
    'Due diligence extension exercised on',
    'Due diligence is extended to: [If exercised, the user needs to update so other dates are based upon the extended date.]',
    'Tenant must be approved by',
    'Tenant was approved on',
    'Apply for entitlements for intended use by',
    'Date applied for approvals for intended use',
    'Application for association approval by',
    'Association approval is required by',
    'Date option exercised to extend the deadline for approvals of intended use',
    'Date approvals of intended use is extended to',
    'Apply for building permits by',
    'Building permits approved on',
    'Proposed closing documents by'
  ];

  const leaseMilestones = [
    'Date of LOI',
    'Lease draft prepared',
    'Lease draft reviewed by other party',
    'Lease signed by tenant',
    'Lease signed by landlord',
    'Effective date',
    'Deposit due by',
    'First month rent due by',
    'Date deposit made',
    "Date first month's rent made",
    'Due diligence ends',
    'Architect plans required by',
    'Architect plans received on',
    'Contractor bid received on',
    'Application to association required by',
    'Application made to association on',
    'Approval of association received on',
    'Application made for approval of governing body on',
    'Approval by the governing authority received on',
    'Co or equivalent received on',
    'Date half commission due broker 1 on',
    'Date half commission due broker 2 on',
    'Date remaining commission due broker 1',
    'Date remaining commission due broker 2',
    'Rent commencement date',
    'Possession date',
    'Lease commencement date',
    'Passthrough commencement date',
    'Estoppel letter to be signed by',
    'Insurance to be delivered by'
  ];

  const sellerDetailConstant = [
    {
      label: 'Contact name',
      type: 'string',
      unit: '',
      name: 'seller_detail_name'
    },
    {
      label: 'Company name',
      type: 'string',
      unit: '',
      name: 'seller_detail_company'
    },
    {
      label: 'Title',
      type: 'string',
      unit: '',
      name: 'seller_detail_title'
    },
    {
      label: 'Address',
      type: 'string',
      unit: '',
      name: 'seller_detail_address'
    },
    {
      label: 'City State Zip',
      type: 'string',
      unit: '',
      name: 'seller_detail_city'
    },
    {
      label: 'Email address',
      type: 'string',
      unit: '',
      name: 'seller_detail_email'
    },
    {
      label: 'Cell number',
      type: 'string',
      unit: '',
      name: 'seller_detail_cell_number'
    },
    {
      label: 'Office number',
      type: 'string',
      unit: '',
      name: 'seller_detail_office_number'
    }
  ];
  const buyerDetailConstant = [
    {
      label: 'Contact name',
      type: 'string',
      unit: '',
      name: 'buyer_detail_name'
    },
    {
      label: 'Company name',
      type: 'string',
      unit: '',
      name: 'buyer_detail_company'
    },
    {
      label: 'Title',
      type: 'string',
      unit: '',
      name: 'buyer_detail_title'
    },
    {
      label: 'Address',
      type: 'string',
      unit: '',
      name: 'buyer_detail_address'
    },
    {
      label: 'City State Zip',
      type: 'string',
      unit: '',
      name: 'buyer_detail_city'
    },
    {
      label: 'Email address',
      type: 'string',
      unit: '',
      name: 'buyer_detail_email'
    },
    {
      label: 'Cell number',
      type: 'string',
      unit: '',
      name: 'buyer_detail_cell_number'
    },
    {
      label: 'Office number',
      type: 'string',
      unit: '',
      name: 'buyer_detail_office_number'
    }
  ];

  const sellerattorneyConstant = [
    {
      label: 'Contact name',
      type: 'string',
      unit: '',
      name: 'seller_attorney_name'
    },
    {
      label: 'Company name',
      type: 'string',
      unit: '',
      name: 'seller_attorney_company'
    },
    {
      label: 'Title',
      type: 'string',
      unit: '',
      name: 'seller_attorney_title'
    },
    {
      label: 'Address',
      type: 'string',
      unit: '',
      name: 'seller_attorney_address'
    },
    {
      label: 'City State Zip',
      type: 'string',
      unit: '',
      name: 'seller_attorney_city'
    },
    {
      label: 'Email address',
      type: 'string',
      unit: '',
      name: 'seller_attorney_email'
    },
    {
      label: 'Cell number',
      type: 'string',
      unit: '',
      name: 'seller_attorney_cell_number'
    },
    {
      label: 'Office number',
      type: 'string',
      unit: '',
      name: 'seller_attorney_office_number'
    }
  ];
  const buyerattorneyConstant = [
    {
      label: 'Contact name',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_name'
    },
    {
      label: 'Company name',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_company'
    },
    {
      label: 'Title',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_title'
    },
    {
      label: 'Address',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_address'
    },
    {
      label: 'City State Zip',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_city'
    },
    {
      label: 'Email address',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_email'
    },
    {
      label: 'Cell number',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_cell_number'
    },
    {
      label: 'Office number',
      type: 'string',
      unit: '',
      name: 'buyer_attorney_office_number'
    }
  ];

  const updateDateByName = (dataArray: any, nameToUpdate: any, newDate: any) => {
    return dataArray.map((item: any) => {
      if (item.name === nameToUpdate) {
        console.log('');
        return { ...item, date: newDate };
      }
      return item;
    });
  };
  function addOrSubtractBusinessDays(originalDate: any, numDays: any, direction: any) {
    let daysToProcess = numDays;

    while (daysToProcess > 0) {
      // Move to the next day
      originalDate.setDate(originalDate.getDate() + (direction === 'before' ? -1 : 1));
      const dayOfWeek = originalDate.getDay();

      // Check if the new date is a weekday
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // If it's a weekday, decrease the days to process
        daysToProcess -= 1;
      }
    }

    return originalDate;
  }

  const calculateDates = (range: any, rangeType: any, countFrom: any, name: any) => {
    if (rangeType.includes('Calendar')) {
      console.log('calendar');
      if (rangeType.includes('before')) {
        const date = formValues.find((fv: any) => fv.name === countFrom).date;
        const futureDate = new Date(date);
        futureDate.setDate(futureDate.getDate() - parseInt(range));
        const futureDateString = futureDate.toISOString().split('T')[0];
        const updatedArray = updateDateByName(formValues, name, futureDateString);

        setFormValues(updatedArray);
      } else {
        const date = formValues.find((fv: any) => fv.name === countFrom).date;
        const futureDate = new Date(date);
        futureDate.setDate(futureDate.getDate() + parseInt(range));
        const futureDateString = futureDate.toISOString().split('T')[0];
        const updatedArray = updateDateByName(formValues, name, futureDateString);

        setFormValues(updatedArray);
      }
    } else {
      console.log('business');

      if (rangeType.includes('before')) {
        const date = formValues.find((fv: any) => fv.name === countFrom).date;
        const futureDate = new Date(date);
        const updatedDate = addOrSubtractBusinessDays(futureDate, range, 'before');
        const futureDateString = updatedDate.toISOString().split('T')[0];
        const updatedArray = updateDateByName(formValues, name, futureDateString);

        setFormValues(updatedArray);
      } else {
        const date = formValues.find((fv: any) => fv.name === countFrom).date;
        const futureDate = new Date(date);
        const updatedDate = addOrSubtractBusinessDays(futureDate, range, 'after');
        const futureDateString = updatedDate.toISOString().split('T')[0];
        const updatedArray = updateDateByName(formValues, name, futureDateString);

        setFormValues(updatedArray);
      }
    }
  };
  const handleInputChange = (label: any, value: any) => {
    const inputValue = value.replace(/[^0-9]/g, '');
    const formatted = Number(inputValue).toLocaleString();

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
  };
  const handleStringInputChange = (label: any, value: any) => {
    const inputValue = value;
    const formatted = inputValue;

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
  };
  //rangetype
  const handleSelectChange = (selected: Option[], name: string) => {
    // Check if an object with the specified name exists
    const existing = formValues.find((fv: any) => fv.name === name);

    if (existing) {
      // Update the existing object
      const updatedFormValues = formValues.map((fv: any) =>
        fv.name === name ? { ...fv, rangeType: selected[0].value } : fv
      );
      setFormValues(updatedFormValues);
      if (existing.range && existing.countFrom) {
        calculateDates(existing.range, selected[0].value, existing.countFrom, existing.name);
      }
    } else {
      // Add a new object
      const newObject = {
        name: name,
        date: '',
        range: '',
        rangeType: selected[0].value,
        countFrom: ''
      };
      setFormValues([...formValues, newObject]);
    }
    console.log(formValues);
  };
  const handleCountFromChange = (selected: Option[], name: string) => {
    // Check if an object with the specified name exists
    const existing = formValues.find((fv: any) => fv.name === name);
    console.log(formValues, 'existing');
    if (existing) {
      // Update the existing object
      const updatedFormValues = formValues.map((fv: any) =>
        fv.name === name ? { ...fv, countFrom: selected[0].value } : fv
      );
      setFormValues(updatedFormValues);
      if (existing.range && existing.rangeType) {
        calculateDates(existing.range, existing.rangeType, selected[0].value, existing.name);
      }
    } else {
      // Add a new object
      const newObject = {
        name: name,
        date: '',
        range: '',
        countFrom: selected[0].value,
        rangeType: ''
      };
      setFormValues([...formValues, newObject]);
    }
    console.log(formValues);
  };
  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const rangeValue = event.target.value;

    // Check if an object with the specified name exists
    const existing = formValues.find((fv: any) => fv.name === name);

    if (existing) {
      // Update the existing object
      const updatedFormValues = formValues.map((fv: any) =>
        fv.name === name ? { ...fv, range: rangeValue } : fv
      );
      setFormValues(updatedFormValues);
    } else {
      // Add a new object
      const newObject = { name: name, range: rangeValue, date: '', rangeType: '', countFrom: '' };
      setFormValues([...formValues, newObject]);
    }
    console.log(formValues);
  };
  const handleDateChange = (event: any, name: string) => {
    const dateValue = event;

    // Check if an object with the specified name exists
    const existing = formValues.find((fv: any) => fv.name === name);

    if (existing) {
      // Update the existing object
      const updatedFormValues = formValues.map((fv: any) =>
        fv.name === name ? { ...fv, date: dateValue } : fv
      );
      setFormValues(updatedFormValues);
    } else {
      // Add a new object
      const newObject = { name: name, date: dateValue, range: '', rangeType: '', countFrom: '' };
      setFormValues([...formValues, newObject]);
    }
    console.log(formValues);
  };

  const getExistingDate = (formValues: any, name: string) => {
    return formValues.find((fv: any) => fv.name === name)?.date || '';
  };

  const [otherTags, setOtherTags] = useState<any>(['Enter title 1']);

  const handleChange = (e: any, setFn: any) => {
    setFn((prevArray: any) => {
      // Create a copy of the previous array
      const newArray = [...prevArray];
      // Modify the last element
      newArray[newArray.length - 1] = e.target.value;
      // Return the new array to set it as the new state
      return newArray;
    });
  };

  return (
    <div className={styles.calc_right} ref={r}>
      <div className={styles.calc_right_div_bg}>
        <div className={styles.calc_right_div_container}>
          <div className={styles.header}>Critical date calculator</div>

          <div className={styles.radio_group}>
            <label>
              <input
                type='radio'
                value='purchaseSale'
                checked={option === 'purchaseSale'}
                onChange={() => {
                  setOption('purchaseSale');
                  setFormValues([]);
                  setOtherTags(['']);
                }}
              />
              Purchase and sale of commercial real estate
            </label>
            <label>
              <input
                type='radio'
                value='leasing'
                checked={option === 'leasing'}
                onChange={() => {
                  setOption('leasing');
                  setFormValues([]);
                  setOtherTags(['']);
                }}
              />
              Leasing of commercial real estate
            </label>
            <label>
              <input
                type='radio'
                value='other'
                checked={option === 'other'}
                onChange={() => {
                  setOption('other');
                  setFormValues([]);
                  setOtherTags(['Enter title 1']);
                }}
              />
              Other
            </label>
          </div>

          {option === 'purchaseSale' && (
            <div>
              {purchaseSaleArray.map((el, i) => {
                const existingDate = formValues.find((fv: any) => fv.name === el)?.date || '';
                const existingRange = formValues.find((fv: any) => fv.name === el)?.range || '';
                const existingRangeType =
                  formValues.find((fv: any) => fv.name === el)?.rangeType || '';
                const existingCountFrom =
                  formValues.find((fv: any) => fv.name === el)?.countFrom || '';
                const getElementsWithDate = (
                  formValues: any
                ): { label: string; value: string }[] => {
                  return formValues
                    .filter((fv: any) => fv.date !== '')
                    .map((fv: any) => ({ label: fv.name, value: fv.name }));
                };
                return (
                  <div className={styles.date_row}>
                    <div className={styles.date_row_label}>{el}</div>
                    <div className={styles.date_row_input_group}>
                      {/* <input
                        type='date'
                        value={existingDate}
                        onChange={event => handleDateChange(event, el)}
                        className={styles.date_row_date}
                      /> */}
                      <DatePicker
                        placeholderText='mm/dd/yyyy'
                        selected={existingDate}
                        onChange={(date: any) => handleDateChange(date, el)}
                        className={styles.date_row_date}
                      />
                      <div className={styles.date_row_range_group}>
                        <input
                          type='number'
                          value={existingRange}
                          onChange={event => handleRangeChange(event, el)}
                          className={styles.date_row_range}
                        />
                        <Select
                          options={rangeTypeOption}
                          values={
                            existingRangeType
                              ? [{ label: existingRangeType, value: existingRangeType }]
                              : []
                          }
                          onChange={selected => handleSelectChange(selected, el)}
                          multi={false}
                          className={styles.date_row_rangeType}
                          searchable={false}
                        />
                      </div>

                      <Select
                        options={getElementsWithDate.length ? getElementsWithDate(formValues) : []}
                        values={
                          existingCountFrom
                            ? [{ label: existingCountFrom, value: existingCountFrom }]
                            : []
                        }
                        onChange={selected => handleCountFromChange(selected, el)}
                        multi={false}
                        className={styles.date_row_countFrom}
                        searchable={false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {option === 'leasing' && (
            <div>
              {leaseMilestones.map((el, i) => {
                const existingDate = formValues.find((fv: any) => fv.name === el)?.date || '';
                const existingRange = formValues.find((fv: any) => fv.name === el)?.range || '';
                const existingRangeType =
                  formValues.find((fv: any) => fv.name === el)?.rangeType || '';
                const existingCountFrom =
                  formValues.find((fv: any) => fv.name === el)?.countFrom || '';
                const getElementsWithDate = (
                  formValues: any
                ): { label: string; value: string }[] => {
                  return formValues
                    .filter((fv: any) => fv.date !== '')
                    .map((fv: any) => ({ label: fv.name, value: fv.name }));
                };
                return (
                  <div className={styles.date_row}>
                    <div className={styles.date_row_label}>{el}</div>
                    <div className={styles.date_row_input_group}>
                      <DatePicker
                        placeholderText='mm/dd/yyyy'
                        selected={existingDate}
                        onChange={(date: any) => handleDateChange(date, el)}
                        className={styles.date_row_date}
                      />
                      <div className={styles.date_row_range_group}>
                        <input
                          type='number'
                          value={existingRange}
                          onChange={event => handleRangeChange(event, el)}
                          className={styles.date_row_range}
                        />
                        <Select
                          options={rangeTypeOption}
                          values={
                            existingRangeType
                              ? [{ label: existingRangeType, value: existingRangeType }]
                              : []
                          }
                          onChange={selected => handleSelectChange(selected, el)}
                          multi={false}
                          className={styles.date_row_rangeType}
                          searchable={false}
                        />
                      </div>

                      <Select
                        options={getElementsWithDate.length ? getElementsWithDate(formValues) : []}
                        values={
                          existingCountFrom
                            ? [{ label: existingCountFrom, value: existingCountFrom }]
                            : []
                        }
                        onChange={selected => handleCountFromChange(selected, el)}
                        multi={false}
                        className={styles.date_row_countFrom}
                        searchable={false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {option === 'other' && (
            <div>
              <div>
                {otherTags.map((el: any, i: any) => {
                  console.log(otherTags, 'otherTags');
                  const existingDate = getExistingDate(formValues, `${otherTags[i]}`);
                  const existingName = `${otherTags[i]}`;
                  const existingRange =
                    formValues.find((fv: any) => fv.name === `${otherTags[i]}`)?.range || '';
                  const existingRangeType =
                    formValues.find((fv: any) => fv.name === `${otherTags[i]}`)?.rangeType || '';
                  const existingCountFrom =
                    formValues.find((fv: any) => fv.name === `${otherTags[i]}`)?.countFrom || '';
                  const getElementsWithDate = (
                    formValues: any
                  ): { label: string; value: string }[] => {
                    return formValues
                      .filter((fv: any) => fv.date !== '')
                      .map((fv: any) => ({ label: fv.name, value: fv.name }));
                  };
                  return (
                    <div className={styles.date_row} key={i}>
                      <EditText
                        inline
                        name={otherTags[i]}
                        // placeholder={`Enter title ${i + 1}`}
                        value={existingName}
                        onChange={(e: any) => handleChange(e, setOtherTags)}
                        key={i}
                        style={{ minWidth: '300px' }}
                      />
                      {/* <div className={styles.date_row_label}>{el}</div> */}
                      <div className={styles.date_row_input_group}>
                        <DatePicker
                          placeholderText='mm/dd/yyyy'
                          selected={getExistingDate(formValues, `${otherTags[i]}`)}
                          onChange={(date: any) => handleDateChange(date, `${otherTags[i]}`)}
                          className={styles.date_row_date}
                        />

                        <div className={styles.date_row_range_group}>
                          <input
                            type='number'
                            value={existingRange}
                            onChange={event => handleRangeChange(event, `${otherTags[i]}`)}
                            className={styles.date_row_range}
                          />
                          <Select
                            options={rangeTypeOption}
                            values={
                              existingRangeType
                                ? [{ label: existingRangeType, value: `${otherTags[i]}` }]
                                : []
                            }
                            onChange={selected => handleSelectChange(selected, `${otherTags[i]}`)}
                            multi={false}
                            className={styles.date_row_rangeType}
                            searchable={false}
                          />
                        </div>

                        <Select
                          options={
                            getElementsWithDate.length ? getElementsWithDate(formValues) : []
                          }
                          values={
                            existingCountFrom
                              ? [{ label: existingCountFrom, value: `${otherTags[i]}` }]
                              : []
                          }
                          onChange={selected => handleCountFromChange(selected, `${otherTags[i]}`)}
                          multi={false}
                          className={styles.date_row_countFrom}
                          searchable={false}
                        />
                      </div>
                    </div>
                  );
                })}
                <div
                  className={styles.add_more}
                  onClick={() => {
                    setOtherTags((prev: any) => [...prev, `Enter title ${otherTags.length + 1}`]);
                  }}
                >
                  + Add More Fields
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.calc_right_div_container}>
          <div className={styles.user_detail}>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Seller details</div>
              <div className={s.form}>
                <FormItem
                  formArray={sellerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Buyer details</div>

              <div className={s.form}>
                <FormItem
                  formArray={buyerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.calc_right_div_container}>
          <div className={styles.user_detail}>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Seller's Attorney</div>
              <div className={s.form}>
                <FormItem
                  formArray={sellerattorneyConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Buyer's Attorney</div>

              <div className={s.form}>
                <FormItem
                  formArray={buyerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.calc_right_div_container}>
          <div className={styles.user_detail}>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Seller's Broker</div>
              <div className={s.form}>
                <FormItem
                  formArray={sellerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Buyer's Broker</div>

              <div className={s.form}>
                <FormItem
                  formArray={buyerattorneyConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.calc_right_div_container}>
          <div className={styles.user_detail}>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Closing Agent</div>
              <div className={s.form}>
                <FormItem
                  formArray={sellerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div className={styles.section_header}>Title Agent</div>

              <div className={s.form}>
                <FormItem
                  formArray={buyerDetailConstant}
                  handleInputChange={() => {}}
                  handleStringInputChange={() => {}}
                  formValues={formValues}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriticalDateCalculator;
