import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './set-project-goal.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

const SetProjectGoalModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  setShowChart,
  queryClient
}) => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  const setGoalPost = async (data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/project/setProjectGoal/${storage.get('projectId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data
    };

    return await axios(config);
  };
  const { status: mutationStatus, mutateAsync: setGoal } = useMutation(setGoalPost, {
    onSuccess: res => {
      if (setShowChart) setShowChart(false);
      setShowModal(false);
      queryClient.invalidateQueries(`projectdetails`);
      enqueueSnackbar(res.data.msg, { variant: 'success', header: 'Success' });
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  const onSubmit = (data: any) => {};

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Set project goal
        </ModalHeader>

        <Formik
          initialValues={{
            emailGoalDay: '',
            emailGoalWeek: '',
            emailGoalYear: ''
          }}
          validationSchema={yup.object({
            emailGoalDay: yup.string().trim().required(' Emails to be sent per day is required'),
            emailGoalWeek: yup.string().trim().required(' Emails to be sent per week is required'),
            emailGoalYear: yup.string().trim().required('Emails to be sent per month is required')
          })}
          onSubmit={values => {
            setGoal(values);
          }}
        >
          {props => (
            //TODO: Add member form validation
            <Form>
              <div className={styles.form_wrapper}>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='emailGoalDay'
                      placeholder='Number of emails to send per day'
                      isNumber={true}
                      component={CustomisedTextField}
                      required={true}
                    />
                  </div>
                </div>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='emailGoalWeek'
                      placeholder='Number of emails to send per week'
                      isNumber={true}
                      component={CustomisedTextField}
                      required={true}
                    />
                  </div>
                </div>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='emailGoalYear'
                      placeholder='Number of emails to send per month'
                      isNumber={true}
                      component={CustomisedTextField}
                      required={true}
                    />
                  </div>
                </div>

                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText={'Set Goal'}
                    btnType={BTN_PRIMARY}
                    isDisabled={!(props.isValid && props.dirty)}
                    className={styles.submit_button}
                    isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SetProjectGoalModal;
