import {
  CALCULATE_DAYS_BETWEEN,
  DEBT_COVERAGE_RATIO,
  FLOOR_AREA_RATIO,
  FORM_AMORTIZATION_CALCULATOR,
  FORM_AMORTIZE_BUILDOUT_ALLOWANCE_INTO_LEASE,
  FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR,
  FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR,
  FORM_COMMISSION_CALCULATOR_AND_INVOICE_CREATOR,
  FORM_DECREASE_GRADE_CALCULATOR,
  FORM_DEFEASANCE_CALCULATOR,
  FORM_EFFECTIVE_NET_RENT_CALCULATOR,
  FORM_EXPENSE_PRORATION,
  FORM_HUD,
  FORM_NET_RENT,
  FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR,
  FORM_RAISE_GRADE_CALCULATOR,
  FORM_REAL_COST_OF_CONSTRUCTION_LOAN_CALCULATOR,
  FORM_RENT_PRORATION,
  FORM_RENT_TO_CHARGE,
  MAXIMUM_LAND_COST_CALCULATOR,
  OPERATIONS_EXPENSE_RATIO,
  PROPERTY_INCOME_AND_VALUE,
  RETURN_ON_INVESTMENT,
  UNIT_CONVERSIONS_CALCULATOR,
  VALUE_OF_PROPERTY,
  VALUE_OF_PROPERTY_SUBJECT_TO_GROUND_LEASES
} from './calculators-form';

export const CALCULATORS = [
  {
    id: 1,
    title: 'Land grade calculators',
    icon: 'images/i1.svg',
    subcategories: [FORM_RAISE_GRADE_CALCULATOR, FORM_DECREASE_GRADE_CALCULATOR]
  },
  {
    id: 2,
    title: 'Proration & apportionment calculators',
    icon: 'images/i2.svg',
    subcategories: [FORM_RENT_PRORATION, FORM_EXPENSE_PRORATION, FORM_HUD]
  },
  {
    id: 3,
    title: 'Rent calculators',
    icon: 'images/i3.svg',
    subcategories: [
      FORM_RENT_TO_CHARGE,
      FORM_AMORTIZE_BUILDOUT_ALLOWANCE_INTO_LEASE,
      // FORM_EFFECTIVE_NET_RENT_CALCULATOR,
      FORM_COMMISSION_CALCULATOR_AND_INVOICE_CREATOR,
      FORM_NET_RENT
    ]
  },

  {
    id: 4,
    title: 'Loan calculators',
    icon: 'images/i5.svg',
    subcategories: [
      FORM_AMORTIZATION_CALCULATOR,
      FORM_DEFEASANCE_CALCULATOR
      // FORM_REAL_COST_OF_CONSTRUCTION_LOAN_CALCULATOR
    ]
  },
  {
    id: 5,
    title: 'Break even calculators',
    icon: 'images/i2.svg',
    subcategories: [
      FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR,
      FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR
    ]
  },

  {
    id: 7,
    title: 'Critical date calculator',
    icon: 'images/i4.svg',
    subcategories: [],
    name: 'CRITICAL_DATE_CALCULATOR'
  },
  {
    id: 8,
    title: 'Timing, measurement & ratio calculators',
    icon: 'images/i4.svg',
    subcategories: [
      FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR,
      UNIT_CONVERSIONS_CALCULATOR,
      DEBT_COVERAGE_RATIO,
      FLOOR_AREA_RATIO,
      OPERATIONS_EXPENSE_RATIO,
      CALCULATE_DAYS_BETWEEN
    ]
  },
  {
    id: 9,
    title: 'Property value & investor return calculators',
    icon: 'images/i1.svg',
    subcategories: [
      MAXIMUM_LAND_COST_CALCULATOR,
      VALUE_OF_PROPERTY_SUBJECT_TO_GROUND_LEASES,
      PROPERTY_INCOME_AND_VALUE,
      VALUE_OF_PROPERTY,
      RETURN_ON_INVESTMENT
    ]
  },
  {
    id: 6,
    title: 'Personal financial statement',
    icon: 'images/i3.svg',
    subcategories: [],
    name: 'PERSONAL_FINANCE_STATEMENT'
  }
];
