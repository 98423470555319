export const INPUT_TYPE = {
  dollar: '$',
  sqft: 'SF',
  percent: '%',
  none: '',
  feet: 'FT',
  month: 'Month',
  hash: '#',
  psf: 'PSF',
  days: 'Days',
  date: 'dd/mm/yyyy',
  years: 'Years'
};

export const FORM_RAISE_GRADE_CALCULATOR = {
  title: 'Raise in grade',
  multiSolve: [],

  form: [
    {
      label: 'Height the land will be raised',
      type: 'number',
      unit: INPUT_TYPE.feet,
      name: 'height'
    },
    {
      label: 'Area of land to be raised',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'area'
    },
    {
      label: 'Cost of adding fill per cubic yard',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'cost'
    }
  ],
  name: 'FORM_RAISE_GRADE_CALCULATOR',
  purpose:
    'To help determine the amount of fill needed to achieve the desired land grade. It also calculates the total cost and the number of (15 cubic yard) trucks required for delivery.',
  instructions: `Enter the elevation increase needed in feet.
Specify the land area in square feet (use an area conversion tool if necessary).
Input the delivery cost per cubic yard of fill material.
Click the calculate button to find out the required cubic yards of fill, the total cost, and the number of 15-yard trucks needed for delivery.`
};

export const FORM_DECREASE_GRADE_CALCULATOR = {
  title: 'Decrease in grade',
  multiSolve: [],

  form: [
    {
      label: 'Height the land will be decreased',
      type: 'number',
      unit: INPUT_TYPE.feet,
      name: 'height'
    },
    {
      label: 'Area of land to be decreased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'area'
    },
    {
      label: 'Cost of removing fill per cubic yard',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'cost'
    }
  ],
  name: 'FORM_DECREASE_GRADE_CALCULATOR',

  purpose: `To help determine the amount of fill that needs to be removed to lower the land to the desired grade, it also calculates the total cost of fill removal and estimates the number of trucks required for the job.`,
  instructions: ` Enter the number of feet by which the land needs to be lowered.
Enter the total square footage of the land to be decreased (use the area conversion calculator to convert from acres, meters, etc., to square feet).
Enter the cost of removing fill per cubic yard. The calculator will provide the number of cubic yards of fill to be removed, the total cost, and the number of 15-yard trucks needed for the removal.`
};

export const FORM_RENT_PRORATION = {
  title: 'Rent Proration',
  multiSolve: [],

  form: [
    {
      label: 'Rent amount',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'amount'
    },
    {
      label: 'Rental period',
      type: 'number',
      unit: INPUT_TYPE.days,
      name: 'fullNumDays'
    },
    {
      label: 'Prorate period',
      type: 'number',
      unit: INPUT_TYPE.days,
      name: 'expNumDays'
    }
  ],
  name: 'FORM_RENT_PRORATION',

  purpose: `The purpose is to divide the rent amount among two or more parties, each responsible for a portion. This is useful for property sales or when a tenant moves in or out in the middle of a rental period.`,
  instructions: `Enter the total rent amount and the number of days in the entire rental period. Then, enter the number of days for the proration period.`
};

export const FORM_EXPENSE_PRORATION = {
  title: 'Expense Proration',
  multiSolve: [],

  form: [
    {
      label: 'Expense amount',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'amount'
    },
    {
      label: 'Entire expense period',
      type: 'number',
      unit: INPUT_TYPE.days,
      name: 'fullNumDays'
    },
    {
      label: 'Prorate period',
      type: 'number',
      unit: INPUT_TYPE.days,
      name: 'expNumDays'
    }
  ],
  name: 'FORM_EXPENSE_PRORATION',

  purpose: `The purpose is to divide an expense among two or more parties, each responsible for a portion. This is useful for property sales or when a vendor is terminated in the middle of an expense period.`,
  instructions: `Enter total expense amount and number of days in the entire expense period. Then enter the number of days in the expense period.`
};

export const FORM_RENT_TO_CHARGE = {
  title: 'Rent to charge',
  multiSolve: [
    {
      label: 'Cost of land',
      name: 'costOfLand'
    },
    {
      label: 'Number of leasable square feet of space',
      name: 'leasableSquareFeet'
    },
    {
      label: 'Desired rate of return',
      name: 'returnRate'
    },
    {
      label: 'Construction and soft costs PSF of leasable space',
      name: 'softCosts'
    },
    {
      label: 'Pass throughs PSF',
      name: 'passthroughs'
    },
    {
      label: 'Vacancy factor',
      name: 'vacancyFactor'
    },
    {
      label: 'NNN rent to charge',
      name: 'rentToCharge'
    }
  ],

  form: [
    {
      label: 'Cost of land',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'costOfLand'
    },
    {
      label: 'Number of leasable square feet of space',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasableSquareFeet'
    },
    {
      label: 'Desired rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'returnRate'
    },
    {
      label: 'Construction and soft costs PSF of leasable space',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'softCosts'
    },
    {
      label: 'Pass throughs PSF',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'passthroughs'
    },
    {
      label: 'Vacancy factor',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'vacancyFactor'
    },
    {
      label: 'NNN rent to charge',
      type: 'number',
      unit: '$',
      name: 'rentToCharge'
    }
  ],
  name: 'FORM_RENT_TO_CHARGE',

  purpose:
    'The purpose is to determine the amount of rent to charge to achieve the desired return.',
  instructions:
    'Enter total rent amount and the number of days in the entire time period. Then enter the number of days in the proration period.'
};

export const FORM_AMORTIZE_BUILDOUT_ALLOWANCE_INTO_LEASE = {
  title: 'Amortize buildout allowance into lease',
  multiSolve: [],

  form: [
    {
      label: 'Square feet of premises',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'premises'
    },
    {
      label: 'Buildout allowance',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'buildout'
    },
    {
      label: 'Rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'ror'
    },
    {
      label: 'Amortization period',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'amortization'
    },
    {
      label: 'Additional rent',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'additional_rent'
    }
  ],
  name: 'FORM_AMORTIZE_BUILDOUT_ALLOWANCE_INTO_LEASE',

  purpose: `To determine the necessary rent increase for a landlord to recover a buildout allowance provided to a tenant, including a return on the allowance. This calculator assumes buildout allowances are given 30 days before the first rent payment is due.`,
  instructions: `Enter the total rent amount and the number of days in the entire rental period. Next, enter the number of days in the proration period.`
};

export const FORM_EFFECTIVE_NET_RENT_CALCULATOR = {
  title: 'Effective Net Rent',
  multiSolve: [],

  form: [
    {
      label: 'Square feet of premises',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'premises'
    },
    {
      label: 'Buildout allowance',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'buildout'
    },
    {
      label: 'Rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'ror'
    },
    {
      label: 'Amortization period',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'amortization'
    },
    {
      label: 'Additional rent',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'additional_rent'
    }
  ],
  name: 'FORM_EFFECTIVE_NET_RENT_CALCULATOR',

  purpose:
    'The purpose is to provide a breakdown of the amount of rent due to two or more parties when each is responsible for a portion of the rent. This should be useful for the sale of a property, or when a tenant takes possession or moves in the middle of a rental period.',
  instructions:
    'Enter total rent amount and the number of days in the entire time period. Then enter the number of days in the proration period.'
};

export const FORM_COMMISSION_CALCULATOR_AND_INVOICE_CREATOR = {
  title: 'Commission',
  multiSolve: [],
  form: [
    {
      label: 'Square feet of premises',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'premises'
    },
    {
      label: 'Buildout allowance',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'buildout'
    },
    {
      label: 'Rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'ror'
    },
    {
      label: 'Amortization period',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'amortization'
    },
    {
      label: 'Additional rent',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'additional_rent'
    }
  ],
  name: 'FORM_COMMISSION_CALCULATOR_AND_INVOICE_CREATOR',

  purpose:
    'The purpose of this calculator is to determine the commission due and automatically prepare and invoice and track the amount due until it is paid.',
  instructions:
    'Enter total rent amount and the number of days in the entire time period. Then enter the number of days in the proration period.'
};

export const FORM_PRESENT_AND_FUTURE_CALCULATOR = {
  multiSolve: [],

  title: 'Present and future',
  form: [
    {
      label: 'Present Value',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'present_value'
    },
    {
      label: 'Future Value',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'future_value'
    },
    {
      label: 'Rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'ror'
    },
    {
      label: 'Number of Years',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'amortization'
    }
  ],
  name: 'FORM_COMMISSION_CALCULATOR_AND_INVOICE_CREATOR',

  purpose:
    'The purpose of this calculator is to determine the commission due and automatically prepare and invoice and track the amount due until it is paid.',
  instructions:
    'Enter total rent amount and the number of days in the entire time period. Then enter the number of days in the proration period.'
};

// morgrage calc

export const FORM_AMORTIZATION_CALCULATOR = {
  multiSolve: [],

  title: 'Amortization',
  form: [
    {
      label: 'Loan amount',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'amount'
    },
    {
      label: 'Loan term',
      type: 'number',
      unit: INPUT_TYPE.month,
      name: 'term'
    },
    {
      label: 'Interest rate (APR, computed monthly)',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'rate'
    },
    {
      label: 'View(if desired) % principal and % interest for month',
      type: 'number',
      unit: INPUT_TYPE.hash,
      name: 'period'
    }
  ],
  name: 'FORM_AMORTIZATION_CALCULATOR',
  purpose:
    'Determine monthly loan payments, breakdown of principal and interest, and analyze various cash flow scenarios.',
  instructions: 'Enter loan amount, term, interest rate, and desired payment number.'
};
export const FORM_DEFEASANCE_CALCULATOR = {
  title: 'Defeasance',
  form: [
    {
      label:
        'Yield to maturity of U.S. treasury bonds due closest in time to maturity of original loan',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'yieldToMaturity'
    },
    {
      label: 'Original principal loan amount',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'loanAmount'
    },
    {
      label: 'Amortization term of loan',
      type: 'number',
      unit: INPUT_TYPE.month,
      name: 'amortizationTerm'
    },
    {
      label: 'Original loan term',
      type: 'number',
      unit: INPUT_TYPE.month,
      name: 'maturity'
    },
    {
      label: 'Interest rate of original loan',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'interestRate'
    },
    {
      label: 'Interest days charged per year',
      type: 'select',
      option: [
        '365/360',
        '365/365'
        // { value: '365/360', id: 360 },
        // { value: '365/365', id: 365 }
      ],
      unit: INPUT_TYPE.month,
      name: 'interestDays'
    },
    {
      label: 'Months between defeasance date and original maturity date',
      type: 'number',
      unit: INPUT_TYPE.month,
      name: 'months'
    },
    {
      label: 'Amount of U.S. treasury bonds needed at defeasance date',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'teasury_ammount'
    }
  ],
  multiSolve: [],
  name: 'FORM_DEFEASANCE_CALCULATOR',
  purpose:
    'Calculate defeasance amount for a mortgage, substituting U.S. treasury bonds for real estate collateral.',
  instructions:
    'Enter yield to maturity of U.S. treasury bonds, original loan details, interest days per year, and defeasance date details.'
};

export const FORM_REAL_COST_OF_CONSTRUCTION_LOAN_CALCULATOR = {
  title: 'Real cost of construction loan',
  form: [
    {
      label: 'Loan Closing Costs',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'a'
    },
    {
      label: 'Interest Reserve',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'b'
    },
    {
      label: 'Interest rate on interest reserve',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'c'
    },
    {
      label: 'Date of loan',
      type: 'date',
      unit: INPUT_TYPE.date,
      name: 'd'
    },
    {
      label: 'Interest rate on loan',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'e'
    },
    {
      label: 'Interest rate is based upon',
      type: 'select',
      option: [
        { value: '360/365', id: 360 },
        { value: '365/365', id: 365 }
      ],
      unit: INPUT_TYPE.month,
      name: 'f'
    },
    {
      label: 'Date Loan Paid Back',
      type: 'date',
      unit: INPUT_TYPE.date,
      name: 'g'
    }
  ],
  multiSolve: [],
  name: 'FORM_REAL_COST_OF_CONSTRUCTION_LOAN_CALCULATOR',
  purpose:
    'Determine the effective interest rate of a construction loan, factoring in closing fees and interest on the interest reserve.',
  instructions: 'Fill in all fields, including anticipated draws.'
};

export const FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR = {
  title: 'Break even on operations',
  form: [
    {
      label: 'Leasable square footage in project',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasableSquareFootage'
    },
    {
      label: 'Total amount of square feet leased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'squareFeetLeased'
    },
    {
      label: 'Annual rent for leased space (including passthroughs and other income)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'annualRent'
    },
    {
      label: 'Projected average annual rent PSF of vacant space (including passthroughs)',
      type: 'number',
      unit: INPUT_TYPE.psf,
      name: 'projectedAnnualRent'
    },
    {
      label: 'Fixed annual operating costs',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'fixedCosts'
    },

    {
      label: 'Variable annual operating costs PSF',
      type: 'number',
      unit: INPUT_TYPE.psf,
      name: 'variableCosts'
    },
    {
      label: 'Annual interest on debt',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'annualDebt'
    },
    {
      label: 'Additional number of square feet that need to be leased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'additionalFeetLeased'
    }
  ],
  multiSolve: [
    {
      label: 'Total amount of square feet leased',
      name: 'squareFeetLeased'
    },
    {
      label: 'Annual rent for leased space (including passthroughs and other income)',
      name: 'annualRent'
    },
    {
      label: 'Projected average annual rent PSF of vacant space (including passthroughs)',
      name: 'projectedAnnualRent'
    },
    {
      label: 'Fixed annual operating costs',
      name: 'fixedCosts'
    },

    {
      label: 'Variable annual operating costs PSF',
      name: 'variableCosts'
    },
    {
      label: 'Annual interest on debt',
      name: 'annualDebt'
    },
    {
      label: 'Additional number of square feet that need to be leased',
      name: 'additionalFeetLeased'
    }
  ],
  name: 'FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR',
  purpose:
    'Determine the occupancy percentage required for a project to break even, providing precise insights into profitability thresholds.',
  instructions:
    'Enter total leasable square footage, leased square footage, rent, passthroughs, other income, average rent per square foot, fixed operating costs, variable annual costs per square foot, and annual debt service. Leave a field blank to solve for additional square footage needed.'
};

export const FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR = {
  title: 'Break even on sale for merchant developer',
  form: [
    {
      label: 'Leasable square footage in project',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasableSquareFootage'
    },
    {
      label: 'Total amount of square feet leased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'squareFeetLeased'
    },
    {
      label: 'Annual rent for leased space (including passthroughs and other income)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'annualRent'
    },
    {
      label: 'Projected average annual rent PSF of vacant space (including passthroughs)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'projectedAverageAnnualRent'
    },
    {
      label: 'Fixed annual operating costs',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'fixedAnnualCosts'
    },

    {
      label: 'Variable annual operating osts',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'variableAnnualCosts'
    },
    {
      label: 'Anticipated CAP rate on sale',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'capRate'
    },
    {
      label: 'Fixed land, construction & soft costs',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'fixedLandConstructionSoftCosts'
    },
    {
      label: 'Projected closing costs of sale',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'projectedClosingCosts'
    },
    {
      label: 'Projected brokerage fee on sale',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'projectedBrokerageFee'
    },
    {
      label: 'Variable land, construction and soft costs PSF leased',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'variableLandConstructionSoftCosts'
    },
    {
      label: 'Additional number of square feet that need to be leased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'squareFeetToBeLeased'
    }
  ],
  multiSolve: [
    {
      label: 'Total amount of square feet leased',
      name: 'squareFeetLeased'
    },
    {
      label: 'Annual rent for leased space (including passthroughs and other income)',
      name: 'annualRent'
    },
    {
      label: 'Projected average annual rent PSF of vacant space (including passthroughs)',
      name: 'projectedAverageAnnualRent'
    },
    {
      label: 'Fixed annual operating costs',
      name: 'fixedAnnualCosts'
    },
    {
      label: 'Variable annual operating costs',
      name: 'variableAnnualCosts'
    },
    {
      label: 'Anticipated CAP rate on sale',
      name: 'capRate'
    },
    {
      label: 'Fixed land, construction & soft costs',
      name: 'fixedLandConstructionSoftCosts'
    },
    {
      label: 'Projected closing costs of sale',
      name: 'projectedClosingCosts'
    },
    {
      label: 'Projected brokerage fee on sale',
      name: 'projectedBrokerageFee'
    },
    {
      label: 'Variable land, construction and soft costs PSF leased',
      name: 'variableLandConstructionSoftCosts'
    },
    {
      label: 'Additional number of square feet that need to be leased',
      name: 'squareFeetToBeLeased'
    }
  ],
  name: 'FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR',
  purpose:
    'The break even calculator determines the portion of the project that needs to be rented in order to break even. The owner will know the precise amount of occupancy needed to begin making a profit and the amount below which the owner will start losing money.',
  instructions:
    'Enter the total leasable (leased and vacant) square footage in the project. Next enter the actual square footage that has been leased. Next enter the total amount of rent and pass throughs generated by the total amount of space leased as well as the other income generated. Then enter the projected average rent per square foot (PSF) for the vacant space. Next, enter the total fixed operating costs (not including reserves, leasing commissions, tenant buildout or tenant allowances) of the project. This includes all costs that are not affected by the amount of occupancy such as: real estate taxes, insurance, lawn maintenance, etc. Then enter variable annual costs PSF. Variable costs may include water and electric, if not separately metered, and waste removal if paid for by landlord or a part of common area maintenance charges passed through to tenants. Finally, enter the amount of the annual debt service. Other fields can be solved for by leaving the field to be solved for blank and additional number of square feet that need to be leased.'
};

export const FORM_HUD = {
  title: 'HUD 1 Settlement statement',
  name: 'FORM_HUD',
  purpose: '',
  instructions: '',
  multiSolve: [],
  form: []
};

// Timing Measurement
export const FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR = {
  title: 'Present or future value',
  multiSolve: [
    {
      label: 'Present value',
      name: 'present'
    },
    {
      label: 'Future value',
      name: 'future'
    }
  ],

  form: [
    {
      label: 'Present value',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'present'
    },
    {
      label: 'Future value',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'future'
    },
    {
      label: 'Rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'rate'
    },
    {
      label: 'Number of years',
      type: 'number',
      unit: INPUT_TYPE.years,
      name: 'years'
    }
  ],
  name: 'FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR',
  purpose: 'This determines the present value or future value of an amount for a specific period.',
  instructions:
    'Choose either the future value or the present value from the drop down. Then enter the value, the rate of return and the number of years.'
};
export const UNIT_CONVERSIONS_CALCULATOR = {
  title: 'Unit conversions',
  multiSolve: [],

  form: [
    {
      label: 'Convert',
      type: 'numberAndSelect',
      unit: INPUT_TYPE.none,
      name: 'number',
      nameSelect: 'formType',
      option: [
        'square inches',
        'square feet',
        'square yards',
        'square meters',
        'square miles',
        'acres'
      ]
    }
  ],
  name: 'UNIT_CONVERSIONS_CALCULATOR',
  purpose: 'Use this tool to convert between different units of measurement.',
  instructions: `Select either future value or present value from the dropdown menu. Then, enter the amount, rate of return, and duration.`
};
export const DEBT_COVERAGE_RATIO = {
  title: 'Debt coverage ratio',
  multiSolve: [
    {
      label: 'Net operating income (NOI)',
      name: 'noival'
    },
    {
      label: 'Annual debt service',
      name: 'annualdebt'
    },
    {
      label: 'Debt coverage ratio',
      name: 'debtcovratio'
    }
  ],

  form: [
    {
      label: 'Net operating income (NOI)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'noival'
    },
    {
      label: 'Annual debt service',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'annualdebt'
    },
    {
      label: 'Debt coverage ratio',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'debtcovratio'
    }
  ],
  name: 'DEBT_COVERAGE_RATIO',
  purpose: 'Ensure appropriate debt for income ratio, considering bank requirements.',
  instructions:
    'Select the variable to solve for on the drop down. Then enter information for the remaining fields.'
};
export const FLOOR_AREA_RATIO = {
  title: 'Floor area ratio',
  multiSolve: [
    {
      label: 'Size of building',
      name: 'bui'
    },
    {
      label: 'Size of land',
      name: 'land'
    },
    {
      label: 'Floor area ratio',
      name: 'flratio'
    }
  ],

  form: [
    {
      label: 'Size of building',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'bui'
    },
    {
      label: 'Size of land',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'land'
    },
    {
      label: 'Floor area ratio',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'flratio'
    }
  ],
  name: 'FLOOR_AREA_RATIO',
  purpose:
    'Determine the portion of land that is to be covered to be sure it is in compliance with the local building code.',
  instructions:
    'Select from the drop down for the variable you wish to solve. Then fill in all of the other fields.'
};
export const OPERATIONS_EXPENSE_RATIO = {
  title: 'Operations expense ratio',
  multiSolve: [
    {
      label: 'Operating expenses',
      name: 'opexp'
    },
    {
      label: 'Gross rental income',
      name: 'gross'
    },
    {
      label: 'Operating expense ratio',
      name: 'opexpratio'
    }
  ],

  form: [
    {
      label: 'Operating expenses',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'opexp'
    },
    {
      label: 'Gross rental income',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'gross'
    },
    {
      label: 'Operating expense ratio',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'opexpratio'
    }
  ],
  name: 'OPERATIONS_EXPENSE_RATIO',
  purpose:
    'The purpose of the operating expense ratio calculator is to determine what portion of the gross rental income, the operating expenses comprise.',
  instructions:
    'Fill in the radio button for the variable you wish to solve. Then fill in all of the other fields and press calculate.'
};

export const MAXIMUM_LAND_COST_CALCULATOR = {
  title: 'Maximum land cost',
  multiSolve: [
    {
      label: 'Rental rate',
      name: 'rentToCharge'
    },
    {
      label: 'Vacancy factor',
      name: 'vacancyFactor'
    },
    {
      label: 'Construction and soft costs PSF of leasable space',
      name: 'softCosts'
    },
    {
      label: 'Desired rate of return',
      name: 'returnRate'
    },
    {
      label: 'Size of space',
      name: 'leasableSquareFeet'
    },
    {
      label: 'Maximum land cost',
      name: 'costOfLand'
    },
    {
      label: 'Passthroughs PSF',
      name: 'passthroughs'
    }
  ],
  form: [
    {
      label: 'Rental rate',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'rentToCharge'
    },
    {
      label: 'Vacancy factor',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'vacancyFactor'
    },
    {
      label: 'Construction and soft costs PSF of leasable space',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'softCosts'
    },
    {
      label: 'Desired rate of return',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'returnRate'
    },
    {
      label: 'Size of space',
      type: 'number',
      unit: INPUT_TYPE.psf,
      name: 'leasableSquareFeet'
    },
    {
      label: 'Passthroughs PSF',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'passthroughs'
    },
    {
      label: 'Maximum land cost',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'costOfLand'
    },

    {
      label: 'Number of acres of land',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'acres'
    }
  ],
  name: 'MAXIMUM_LAND_COST_CALCULATOR',
  purpose:
    'The purpose is to determine the maximum amount to pay for land, given the size of project, likely rents, construction and soft costs per square foot (PSF), occupancy and desired return.',
  instructions:
    'To figure out the maximum land cost, in the above fields enter the rental rate PSF of leasable space, the construction and soft costs PSF of leasable space, the desired return, the number of leasable square feet, and the number of acres. Lastly, click for calculation.'
};

export const VALUE_OF_PROPERTY_SUBJECT_TO_GROUND_LEASES = {
  title: 'Value of property subject to a ground lease',
  multiSolve: [],
  form: [
    {
      label: 'Annual net operating income (NOI)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'noi'
    },
    {
      label: 'Size of the space being leased',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasableSquareFeet'
    },
    {
      label: 'CAP rate',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'capRate'
    },
    {
      label: 'Years remaining on ground lease',
      type: 'number',
      unit: INPUT_TYPE.years,
      name: 'yearsRemaining'
    }
  ],
  name: 'VALUE_OF_PROPERTY_SUBJECT_TO_GROUND_LEASES',
  purpose: 'Determine the value of a property subject to a ground lease.',
  instructions:
    'Enter net operating income, leasable square feet, CAP rate, and remaining lease years.'
};

export const PROPERTY_INCOME_AND_VALUE = {
  title: 'Property income & value',
  multiSolve: [],
  form: [
    {
      label: 'Leasable space',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasablesqf'
    },
    {
      label: 'Loan to value',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'loan2value'
    },
    {
      label: 'Interest rate on loan',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'interestrate'
    },
    {
      label: 'Amortization of loan',
      type: 'number',
      unit: INPUT_TYPE.years,
      name: 'amortization'
    },
    {
      label: 'CAP Rate',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'caprate'
    },
    {
      label: 'Portion of property value allocable to the land',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'propallocable'
    },
    {
      label: 'Gross scheduled income',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'grossincome'
    },
    {
      label: 'Vacancy and credit allowance',
      type: 'numberAndSelect',
      unit: INPUT_TYPE.none,
      name: 'vacorcred',
      nameSelect: 'vacorcredtype',
      option: ['%', '$']
    },

    {
      label: 'Operating expenses',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'opexpenses'
    },
    {
      label: 'Capital expenses',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'capexpenses'
    },
    {
      label: 'Tax bracket',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'taxbracket'
    },
    {
      label: 'Type of property',
      type: 'select',
      option: ['Residential', 'Non-Residential'],
      unit: INPUT_TYPE.none,
      name: 'typeprop'
    }
  ],
  name: 'PROPERTY_INCOME_AND_VALUE',
  purpose:
    'The purpose of the Property income & value calculator is to determine the value of a property given the net operating income (NOI). The calculator assists in determining the NOI."',
  instructions:
    'Fill in all fields. If the correct answer is none, or zero "0", enter "0". Be sure to choose either "$" or "%" for Vacancy and Credit Allowance and Residential and Non-Residential for type of property.'
};

export const VALUE_OF_PROPERTY = {
  title: 'Value of property',
  multiSolve: [],
  form: [
    {
      label: 'Net operating income (NOI)',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'noi'
    },
    {
      label: 'Size of property',
      type: 'number',
      unit: INPUT_TYPE.sqft,
      name: 'leasableSquareFeet'
    },
    {
      label: 'CAP Rate',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'capRate'
    },
    {
      label: 'Loan to value',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'loantovalue'
    },
    {
      label: 'Interest rate on loan',
      type: 'number',
      unit: INPUT_TYPE.percent,
      name: 'interestrate'
    },
    {
      label: 'Amortization of loan',
      type: 'number',
      unit: INPUT_TYPE.years,
      name: 'amortization'
    }
  ],
  name: 'VALUE_OF_PROPERTY',
  purpose:
    'Determine property value and leveraged return with known CAP Rate and loan information.',
  instructions: 'Fill in all fields. " Use zero "0" for no loan or interest rate.'
};
{
  /* <label>Principal Amount:</label>
          <input name="principal" type="number" onChange={handleFieldChange} />
          
          <label>Annual Rate (%):</label>
          <input name="annualRate" type="number" onChange={handleFieldChange} />
          
          <label>Number of Days:</label>
          <input name="days" type="number" onChange={handleFieldChange} /> */
}
// <label>Minimum Days:</label>
// <label>Total Profit:</label>
export const RETURN_ON_INVESTMENT = {
  title: 'Investor return',
  multiSolve: [
    { label: 'Preferred Return', name: 'preferredReturn' },
    { label: 'Preferred Return with Min Days', name: 'preferredReturnWithMinDays' },
    { label: 'Percentage of Profit', name: 'percentageOfProfit' },
    { label: 'Combination', name: 'combination' }
  ],
  form: [],
  // form: [
  //   [
  //     {
  //       label: 'Principal Amount',
  //       type: 'number',
  //       unit: INPUT_TYPE.dollar,
  //       name: 'preferredReturn'
  //     },
  //     {
  //       label: 'Annual Rate (%)',
  //       type: 'number',
  //       unit: INPUT_TYPE.percent,
  //       name: 'annualRate'
  //     },
  //     {
  //       label: 'Number of Days',
  //       type: 'number',
  //       unit: INPUT_TYPE.days,
  //       name: 'days'
  //     },
  //     {
  //       label: 'Minimum Days',
  //       type: 'number',
  //       unit: INPUT_TYPE.days,
  //       name: 'minDays'
  //     },
  //     {
  //       label: 'Total Profit',
  //       type: 'number',
  //       unit: INPUT_TYPE.dollar,
  //       name: 'totalProfit'
  //     },
  //     {
  //       label: 'Profit Percentage (%)',
  //       type: 'number',
  //       unit: INPUT_TYPE.percent,
  //       name: 'profitPercentage'
  //     }
  //   ]
  // ],
  name: 'RETURN_ON_INVESTMENT',
  purpose:
    'Determine the portion of land that is to be covered to be sure it is in compliance with the local building code.',
  instructions:
    'Select from the drop down for the variable you wish to solve. Then fill in all of the other fields.'
};

export const CALCULATE_DAYS_BETWEEN = {
  title: 'Calculate days between',
  name: 'CALCULATE_DAYS_BETWEEN',
  purpose: 'Calculate the number of days between two given dates.',
  instructions: 'Enter starting and ending dates.',
  multiSolve: [],
  form: []
};

export const FORM_NET_RENT = {
  title: 'Net effective rent',
  multiSolve: [],
  form: [
    {
      label: 'Lease term',
      type: 'number',
      unit: INPUT_TYPE.years,
      name: 'term'
    },
    {
      label: 'Monthly rent',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'br'
    },
    {
      label: 'No. of rent free months',
      type: 'number',
      unit: INPUT_TYPE.none,
      name: 'n'
    },

    {
      label: 'Tenant cash allowance',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'ta'
    },
    {
      label: 'Operating costs',
      type: 'number',
      unit: INPUT_TYPE.dollar,
      name: 'oc'
    }
  ],
  name: 'FORM_NET_RENT',
  purpose:
    'Determine the portion of land that is to be covered to be sure it is in compliance with the local building code.',
  instructions:
    'Select from the drop down for the variable you wish to solve. Then fill in all of the other fields.'
};
