import React from 'react';
import BarChart from '../chart/BarChart';
import { props } from './model';
import SingleValueCard from '../statistic';
import styles from '../../../screens/report/report.module.scss';

const ProspectorComparision: React.FC<props> = ({ overallMetrics, prospectorsData }) => {
  const statistic = [
    {
      img: './images/email_sent.svg',
      count: overallMetrics.emailSent,
      des: 'Emails sent',
      background: '#FFF7F7'
    },
    {
      img: './images/email_opened.svg',
      count: overallMetrics.emailOpened,
      des: 'Emails opened',
      background: '#F3F8FF',
      percentage: overallMetrics.emailOpenedPercentage && `${overallMetrics.emailOpenedPercentage}%`
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.uniqueSent,
      des: 'Unique contact sent',
      background: '#F0F0FF'
    },
    {
      img: './images/average_user.svg',
      count: overallMetrics.uniqueOpened,
      des: 'Unique contact opened',
      background: '#FFF9FE',
      percentage:
        overallMetrics.uniqueOpenedPercentage && `${overallMetrics.uniqueOpenedPercentage}%`
    },
    {
      img: './images/email_sent.svg',
      count: overallMetrics.declined,
      des: 'Declined',
      background: '#FFF9FE'
    },
    {
      img: './images/email_opened.svg',
      count: overallMetrics.deadLeads,
      des: 'Dead Leads',
      background: '#FFF9FE'
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.tour,
      des: 'Tour requested',
      background: '#FFF9FE'
    },
    {
      img: './images/average_user.svg',
      count: overallMetrics.offer,
      des: 'Made offer',
      background: '#FFF9FE'
    },
    {
      img: './images/email_sent.svg',
      count: overallMetrics.leads,
      des: 'Leads',
      background: '#FFF7F7'
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.prospect,
      des: 'Prospects',
      background: '#FFF9FE'
    }
  ];

  return (
    <div className={styles.charts_container}>
      <>
        <div className={styles.prospector_header}>{}</div>
        <div className={styles.statistic_wrapper}>
          {statistic.map((item: any) => (
            <SingleValueCard
              image={item.img}
              count={item.count}
              description={item.des}
              background={item.background}
              percentage={item.percentage}
            />
          ))}
        </div>
      </>

      <BarChart prospectorsData={prospectorsData} />
    </div>
  );
};

export default ProspectorComparision;
